<template>
  <div class="terminal-parameter">
    <el-form
      ref="form"
      :model="form"
      :inline="true"
      size="small"
      :rules="formRules"
      class="dialog-form terminal-form"
    >
      <el-form-item label="车牌号：" v-if="!isBatch">
        {{ data.name }}
      </el-form-item>
      <el-form-item label="终端号：" v-if="!isBatch">
        {{ formatTelNo(data.videos) }}
      </el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="isBatch"
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>

      <!-- 选择车辆： -->
      <!--  -->
      <el-form-item
        label="选择车辆："
        v-if="isBatch"
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
    </el-form>
    <!--  超速报警设置-->
    <p class="form-title form-title2">
      超速报警设置
      <el-button type="primary" size="mini" @click="saveInput(2)"
        >发送指令</el-button
      >
    </p>
    <el-form
      :model="addForm2"
      :inline="true"
      :rules="formRules"
      ref="addForm2"
      label-width="130px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="最高速度(km/h)：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.speedLimit"
          :min="0"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="预警提前量(km/h)：">
        <el-input-number
          v-model="addForm2.preSpeeding"
          placeholder="请输入"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="夜间限速比例(%)：">
        <el-input-number
          v-model="addForm2.nightSpeedLimitPer"
          placeholder="请输入"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="超速持续时间(s)：">
        <el-input-number
          v-model="addForm2.time"
          placeholder="请输入"
          controls-position="right"
          :min="0"
          disabled
        ></el-input-number>
      </el-form-item>
      <el-form-item label="白天时间段：" prop="period">
        <el-time-picker
          is-range
          v-model="addForm2.period"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          format="HH:mm"
        >
        </el-time-picker>
      </el-form-item>
    </el-form>
    <!--疲劳驾驶报警设置  -->
    <p class="form-title form-title2">
      疲劳驾驶报警设置
      <el-button type="primary" size="mini" @click="saveInput(3)"
        >发送指令</el-button
      >
    </p>
    <el-form
      :model="addForm3"
      :inline="true"
      ref="addForm3"
      disabled
      label-width="130px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="连续驾驶(分钟)：">
        <el-input-number
          v-model="addForm3.continueDriveTime"
          placeholder="请输入"
          controls-position="right"
          :min="1"
          :max="255"
        ></el-input-number>
        <el-tooltip
          class="item"
          effect="dark"
          content="最小为1分钟,最大为4小时15分钟(255分钟)"
          placement="top-start"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="当天累计驾驶(分钟)：" label-width="140px">
        <el-input-number
          v-model="addForm3.driverTotalTime"
          placeholder="请输入"
          :min="1"
          controls-position="right"
        ></el-input-number>
        <el-tooltip
          class="item"
          effect="dark"
          content="最小为1分钟"
          placement="top-start"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="预警提前量(分钟)：">
        <el-input-number
          v-model="addForm3.preFatigue"
          placeholder="请输入"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="最小休息(分钟)：" label-width="140px">
        <el-input-number
          v-model="addForm3.minSleepTime"
          placeholder="请输入"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import api from '@/api/static/terminal'
import { setDeviceParams, queryDeviceParams } from '@/api/lib/api'
import { returnVideoArr, formatDate, formatDay } from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 */
    isBatch: {
      type: Boolean,
      default: false
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    number: {
      type: Number,
      default: 300
    }
  },

  data () {
    let validateDate = (rule, value, callback) => {
      if (value && value !== '') {
        let day = formatDay(new Date())
        let startTime = `${day} ${formatDate(value[0]).split(' ')[1]}`
        let endTime = `${day} ${formatDate(value[1]).split(' ')[1]}`
        if (new Date(startTime) >= new Date(endTime)) {
          callback(new Error(`开始时间必须小于结束时间`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      terminalNos: [],
      vehicleInfos: [],

      form: {
        vehIds: [],
        companyIds: []
      },
      addForm2: {
        time: 10, //超速持续时间(s)：
        speedLimit: null, // 最高速度门限（km
        nightSpeedLimitPer: 80, //夜间限速比例
        period: [new Date(2016, 9, 10, 6, 0), new Date(2016, 9, 10, 22, 0)],
        preSpeeding: null //预超速
      },
      addForm3: {
        continueDriveTime: 240, // 连续驾驶时限（分钟）：
        preFatigue: 30, //预疲劳
        driverTotalTime: 480, // 当天累计驾驶时限（分钟）：
        minSleepTime: 20 // 最小休息时间（分钟）：
      },
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],
        period: [{ validator: validateDate, trigger: 'change' }]
      },
      AlarmPromptOption: {
        2: ['speedLimit', 'preSpeeding', 'nightSpeedLimitPer', 'period'],
        3: [
          'continueDriveTime',
          'driverTotalTime',
          'preFatigue',
          'minSleepTime'
        ]
      }
    }
  },

  methods: {
    /** 单个车辆的数据 */
    getData (vehStrIds) {
      this.terminalNos = []
      this.vehicleInfos = []
      this.form.vehIds = vehStrIds
      if (vehStrIds.length > 0)
        this.queryDeviceParams(this.formatTelNo(this.data.videos)) // 单个车辆做查询
    },

    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },

    /** 获取已存的提示内容 */
    queryDeviceParams (data) {
      queryDeviceParams(data).then((res) => {
        if (res.code === 1000) {
          let period = null
          this.addForm2 = { ...this.addForm2, ...res.data }
          if (res.data.period) {
            period = [
              new Date(res.data.period.split('_')[0]),
              new Date(res.data.period.split('_')[1])
            ]
          }
          this.addForm2.period = period
          // this.addForm3 = { ...this.addForm3, ...res.data }
        }
      })
    },
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    /** 所有input的提交 */
    saveInput (key) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let parameter = 0
          let form = `addForm${key}`
          this.AlarmPromptOption[key].forEach((item) => {
            if (
              this[form][item] === null ||
              this[form][item] === undefined ||
              this[form][item] === 'undefined' ||
              this[form][item] === ''
            ) {
              parameter = parameter + 1
            }
          })
          if (parameter === this.AlarmPromptOption[key].length) {
            this.$message.warning('请至少下发一个指令')
            return
          }
          if (key === 2) {
            this.$refs.addForm2.validate((valid) => {
              if (valid) {
                this.sendData(key)
              }
            })
          } else {
            this.sendData(key)
          }
        }
      })
    },
    sendData (key) {
      const terminalNos = []
      const vehicleInfos = []
      let ids = this.form.companyIds.concat(this.form.vehIds)
      queryDeviceByIds({
        ids: ids.join(','),
        system: this.deviceType
      }).then((res) => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            terminalNos.push(element.deviceNo)
            vehicleInfos.push(`${element.cph}#${element.companyName}`)
          })
          this.terminalNos = terminalNos
          this.vehicleInfos = vehicleInfos

          if (this.terminalNos.length > this.number) {
            this.$message.warning(`最多只能对${this.number}个终端批量下发指令`)
            return
          }
          const params = []
          const reqData = {
            deviceNos: this.terminalNos, // 下发的终端
            terminalParams: null // 报警提示类型
          }

          switch (key) {
            //超速报警设置
            case 2:
              const { speedLimit, preSpeeding } = this.addForm2
              //最高速度
              if (speedLimit === 0 || speedLimit) {
                params.push(0x0055, speedLimit)
              }
              //预警提前量
              if (preSpeeding === 0 || preSpeeding) {
                params.push(0x005b, preSpeeding)
              }
              params.push(0x0056, 10) //超速持续时间
              reqData.terminalParams = { ...this.addForm2 }
              if (this.addForm2.period) {
                let period = `${formatDate(
                  this.addForm2.period[0]
                )}_${formatDate(this.addForm2.period[1])}`
                reqData.terminalParams.period = period
              }

              break
            //疲劳驾驶报警设置
            case 3:
              const {
                continueDriveTime,
                preFatigue,
                driverTotalTime,
                minSleepTime
              } = this.addForm3
              if (continueDriveTime) params.push(0x0057, continueDriveTime) //连续驾驶
              //预警提前量
              if (preFatigue === 0 || preFatigue) {
                params.push(0x005c, preFatigue)
              }
              if (driverTotalTime) params.push(0x0058, driverTotalTime) //当天累计驾驶
              //最小休息
              if (minSleepTime === 0 || minSleepTime) {
                params.push(0x0059, minSleepTime)
              }
              reqData.terminalParams = { ...this.addForm3 }

              break
            default:
              break
          }

          this.$yhsdp.GBSetParameters(
            [terminalNos, this.vehicleInfos],
            params,
            this.deviceType
          )
          setDeviceParams(reqData).then((res) => { })
          this.$emit('close')
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.getData([this.data.id])
    }
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.terminal-parameter {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>
