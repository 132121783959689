<template>
  <div class="batchPop">
    <el-dropdown @command="toOperationPage" v-if="dropdownMenu.length > 0">
      <i class="operationMore iconfont its_icon_more" title="批量管理"></i>
      <el-dropdown-menu slot="dropdown" class="carTreeDrop">
        <el-dropdown-item
          v-for="(item, key) in dropdownMenu"
          :key="key"
          :command="item"
          >{{ item }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <!--操作列表-->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="showDialog"
      :width="
        title.indexOf('文本信息下发') > -1 ||
        title.indexOf('点名') > -1 ||
        title.indexOf('禁行设置') > -1
          ? '30%'
          : '50%'
      "
      v-if="showDialog"
      append-to-body
      @close="close"
    >
      <!-- 点名 -->
      <rollCall
        ref="rollCall"
        v-if="title.indexOf('点名') > -1"
        :number="number"
        @close="handleCloseDialog"
      />
      <span
        slot="footer"
        class="dialog-footer"
        v-if="title.indexOf('点名') > -1"
      >
        <el-button type="default" size="small" @click="showDialog = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="onSave"
          >确定下发</el-button
        >
      </span>
      <!--文本信息下发  -->
      <send-information
        :number="number"
        v-if="title.indexOf('文本信息') > -1"
        :isBatch="true"
        @close="handleCloseDialog"
      ></send-information>
      <!-- 设置终端参数 -->
      <terminal-parameter
        v-if="title.indexOf('设置终端参数') > -1"
        :isBatch="true"
        :number="number"
        @close="handleCloseDialog"
      ></terminal-parameter>
      <!-- 设置服务器参数 -->
      <terminal-ip
        v-if="title.indexOf('设置服务器参数') > -1"
        :isBatch="true"
        :number="number"
        @close="handleCloseDialog"
      ></terminal-ip>
      <!-- 设置服务器参数 -->
      <terminal-upgrade
        v-if="title.indexOf('终端升级') > -1"
        :number="number"
        @close="handleCloseDialog"
      ></terminal-upgrade>
      <!-- 批量禁行设置 -->
      <carDrivingHibit
        v-if="title.indexOf('批量禁行设置') > -1"
        :number="number"
        :isBatch="true"
        @close="handleCloseDialog"
      ></carDrivingHibit>
    </el-dialog>
  </div>
</template>

<script>
// 部标设备指令
import rollCall from '@/components/orderOperation/rollCall.vue' // 点名
import sendInformation from '@/components/orderOperation/sendInformation.vue' // 文本信息下发
import terminalParameter from '@/components/orderOperation/terminalParameter.vue' // 设置终端参数
import terminalIP from '@/components/orderOperation/terminalIP.vue' // 设置服务器参数
import terminalUpgrade from '@/components/orderOperation/terminalUpgradeBath.vue' // 设置服务器参数
import carDrivingHibit from '@/components/orderOperation/carDrivingHibit.vue' //客运车辆禁行设置


export default {
  name: 'pop',
  components: {
    rollCall,
    'send-information': sendInformation,
    'terminal-parameter': terminalParameter,
    'terminal-ip': terminalIP,
    'terminal-upgrade': terminalUpgrade,
    carDrivingHibit
  },
  props: {
    data: {
      type: [String, Number, Object]
    }
  },
  data () {
    return {
      number: 300, // 批量处理最大数
      dropdownMenu: [
        '点名',
        '文本信息下发',
        '设置终端参数',
        '设置服务器参数',
        '终端升级',
        '客运车辆禁行设置'
      ], // 批量处理菜单
      showChildMenu: false,
      telNos: [],
      deviceType: '', // 协议类型
      title: '',
      showDialog: false,
      form: {
        vehIds: []
      },
      rules: {
        vehIds: [{ required: true, message: '请选择车辆', trigger: 'blur' }]
      }
    }
  },
  methods: {
    /** 批量处理菜单 */
    getMenuCtrl () {
      const arr = this.dropdownMenu
      for (let i = 0; i < arr.length; i++) {
        if (this.$store.state.menu.nowMenuList.indexOf(arr[i]) === -1) {
          arr.splice(i, 1)
          i = i - 1
        }
      }
    },
    /** 批量下发指令 */
    toOperationPage (type) {
      switch (type) {
        case '点名':
          this.title = '批量点名'
          break
        case '文本信息下发':
          this.title = '批量文本信息下发'
          break
        case '设置终端参数':
          this.title = '批量设置终端参数'
          break
        case '设置服务器参数':
          this.title = '批量设置服务器参数'
          break
        case '终端升级':
          this.$store.dispatch('clear_terminalPropertiesList') //清空查询终端属性数据
          this.$store.dispatch('set_terminalObj', null) //清空查询终端属性数据
          this.title = '批量终端升级'
          break
        case '客运车辆禁行设置':
          this.title = '批量禁行设置'
          break
        default:
          break
      }
      this.showDialog = true
    },
    /** 设置操作菜单界面,点击关闭 */
    handleCloseDialog (flag) {
      this.showDialog = false
      if (!flag) this.$emit('nodeOperation', 'pushTableToAct')
    },
    /** 点名 */
    onSave () {
      this.$refs.rollCall.save()
    },
    close () {
      this.$store.dispatch('clear_terminalPropertiesList') //清空查询终端属性数据
      this.$store.dispatch('set_terminalObj', null) //清空查询终端属性数据
    }
  },
  created () {
    this.getMenuCtrl()
  }
}
</script>

<style lang="scss" scoped>
.batchPop {
  .el-dropdown {
    height: 34px;
  }
  .operationMore {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    background: #fff;
    margin-right: 10px;
  }
}
</style>
