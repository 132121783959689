<template>
  <div class="terminal-upgrade">
    <el-row>
      <el-col :span="8"> 车牌号： {{ data.name }} </el-col>
      <el-col :span="8"> 终端号： {{ formatTelNo(data.videos) }} </el-col>
    </el-row>
    <p class="form-title">终端参数</p>
    <el-row>
      <el-col :span="12"> 终端型号： {{ settingValue.terminalModel }} </el-col>
      <el-col :span="12"> 终端ID： {{ settingValue.terminalID }} </el-col>
      <el-col :span="24"> 终端SIM_ICCID： {{ settingValue.SIM_ICCID }} </el-col>
      <el-col :span="24" :title="settingValue.hardwareVersion">
        硬件版本号： {{ settingValue.hardwareVersion }}
      </el-col>
      <el-col :span="24" :title="settingValue.softwareVersion">
        固件版本号： {{ settingValue.softwareVersion }}
      </el-col>
    </el-row>
    <el-form
      ref="addForm"
      :model="addForm"
      :rules="formRules"
      label-width="96px"
      label-position="right"
      class="demo-form dialog-form dialog-form-inline"
    >
      <!-- 选择升级包： -->
      <el-form-item label="选择升级包：" prop="path">
        <el-select v-model="addForm.path" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.path"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="生产厂家：" prop="type">
        <el-select v-model="addForm.type" placeholder="请选择">
          <el-option label="通立" :value="1"> </el-option>
          <el-option label="通联" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="10px">
        <el-tooltip
          class="tooltip"
          effect="dark"
          content="只支持通立和通联，其他产品请联系工作人员"
          placement="top-start"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <el-button size="small" @click="cancle">取消</el-button>
        <el-button type="primary" size="small" @click="save">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import { returnVideoArr } from '@/common/utils/index'
import { queryUpgradeList, queryFtpAccount } from '@/api/lib/api.js'
export default {
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    settingValue: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data () {
    return {
      addForm: {},
      options: [],
      telNos: [],
      vehicleInfos: [],
      formRules: {
        path: [
          {
            required: true,
            message: '请选择',
            trigger: 'change'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择',
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    //获取数据
    getListByField () {
      queryUpgradeList().then((res) => {
        if (res.code === 1000) {
          this.options = res.data
        } else {
          this.options = []
        }
      })
    },
    //获取ftp数据
    queryFtpAccount () {
      queryFtpAccount().then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...this.addForm, ...res.data }
        }
      })
    },
    //终端升级
    save () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let { ip, user, pwd, path, port } = this.addForm
          let parmas =
            this.addForm.type === 1
              ? [
                `ftp://${ip}${path}`,
                'APN',
                user,
                pwd,
                ip,
                port,
                '',
                '',
                '',
                '',
                '0'
              ]
              : [ip, '', user, pwd, path, port, '', '', '', '', '0']
          this.$yhsdp.GBTermainalUpdate(
            [this.settingValue.telNos, this.settingValue.vehicleInfos],
            parmas,
            this.deviceType
          )
          this.$emit('close')
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    },
    cancle () {
      this.$emit('close', true)
    }
  },
  created () {
    this.getListByField()
    this.queryFtpAccount()
  },
  mounted () {
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.terminal-upgrade {
  padding: 1.5vh 1vw;
  @include themify() {
    background-color: themed("n1");
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin: 10px 1.5vh;
      text-decoration: underline;
      font-weight: 600;
    }
    .el-row {
      .el-col {
        padding: 0 1.5vh;
        height: $base-input-height;
        line-height: $base-input-height;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
</style>
