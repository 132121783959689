<template>
  <div class="tab-table" tabindex="1">
    <div class="hideBox" :style="TabBtnStyle">
      <span class="narrow" v-if="buttonFlag === 2" @click="narrow">
        <i class="iconfont its_zhankai" />
      </span>
      <span class="enlarge" v-if="buttonFlag === 1" @click="enlarge">
        <i class="iconfont its_zhedie" />
      </span>
    </div>
    <el-tabs
      type="card"
      class="monitoringTab"
      v-model="activeName"
      v-show="showTab"
      :style="TabStyle"
      @tab-click="handleClick"
    >
      <el-tab-pane label="实时信息" name="real">
        <realTimeInfo
          ref="real"
          v-if="buttonFlag == 2"
          :tableData="tableData"
          :style="TabContentStyle"
          :total="total"
          @handleCurrentChange="handleCurrentChange"
        ></realTimeInfo>
      </el-tab-pane>
      <el-tab-pane label="报警数据" name="alarm">
        <tableAlarm
          ref="alarm"
          v-if="buttonFlag == 2"
          :style="TabContentStyle"
          :activeName="activeName"
        ></tableAlarm>
      </el-tab-pane>
      <el-tab-pane label="指令信息" name="act">
        <actContent
          ref="act"
          v-if="buttonFlag == 2"
          :style="TabContentStyle"
        ></actContent>
      </el-tab-pane>
      <el-tab-pane label="离线提醒" name="offline">
        <offlineReminder
          ref="offline"
          v-if="buttonFlag == 2"
          :style="TabContentStyle"
        ></offlineReminder>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import realTimeInfo from './components/realTimeInfo.vue'
import tableAlarm from './components/tableAlarm.vue'
import actContent from './components/actContent.vue'
import offlineReminder from './components/offlineReminder.vue'
import { getPostionVhiclePositions } from '@/api/lib/refreshAlarm.js'

export default {
  props: {
    /** 实时信息的请求参数 */
    carPositionReq: {
      type: Array,
      default: function () {
        return []
      }
    },
    /** tab的总高度 */
    TabHeight: {
      type: String,
      default: '10vh'
    },
    /** 是否显示tab */
    showTab: {
      type: Boolean,
      default: true
    },

    /** 具体显示哪一个tab */
    showTabName: {
      type: String,
      default: 'real'
    }
  },
  components: {
    tableAlarm,
    actContent,
    offlineReminder,
    realTimeInfo
  },
  computed: {
    TabStyle: function () {
      return `height:38px;bottom:calc(${this.TabHeight} + 15px);position: relative;` // tab的总高度
    },
    TabContentStyle: function () {
      return `height:calc(${this.TabHeight} - 41px)` // tab的总高度
    },
    TabBtnStyle: function () {
      if (this.TabHeight === '0vh') {
        return `top:-15px`
      } else {
        return `top:calc(-${this.TabHeight} - 15px)`
      }
    }
  },
  watch: {
    showTabName (val) {
      if (val) {
        this.activeName = val
      }
    }
  },
  data () {
    return {
      timer_bus: null,
      activeName: 'real',
      tableData: [],
      buttonFlag: 1,
      realTabHight: 0,
      total: 0,
      currentPage: 1,
      pageSize: 10
    }
  },
  beforeDestroy () {
    clearInterval(this.timer_bus)
  },
  created () { },
  methods: {
    /** 缩小 */
    narrow () {
      this.buttonFlag = 1
      this.realTabHight = 0
      this.$emit('narrow')
    },
    /** 放大 */
    enlarge () {
      this.$emit('enlarge')
      this.buttonFlag = 2
      this.realTabHight = 180
      this.$nextTick(() => {
        this.$refs[this.activeName].computeHeight()
      })
    },

    /* 运营监控___ 展示车辆实时信息 定时请求 */
    showTabTable (reqData) {
      let self = this
      if (self.timer_bus) {
        clearInterval(self.timer_bus)
      }
      if (reqData.length === 0) {
        // 取消选中
        self.clear()
        return
      }
      let timerFn = function () {
        getPostionVhiclePositions({
          ids: reqData.join(','),
          system: sessionStorage.getItem('system'),
          currentPage: self.currentPage,
          pageSize: self.pageSize
        }).then((res) => {
          if (res.code === 1000) {
            self.tableData = res.data.list
            self.total = res.data.total
          }
        })
      }
      timerFn()
      self.timer_bus = setInterval(() => {
        timerFn()
      }, 10000)
    },
    /** 清除定时器 */
    clear () {
      clearInterval(this.timer_bus)
      this.tableData = []
      this.total = 0
      this.currentPage = 1
    },
    /** 分页参数还原 */
    reset () {
      this.currentPage = 1
    },
    /** 实时信息更改页数 */
    handleCurrentChange (page, pageSize) {
      this.currentPage = page
      this.pageSize = pageSize
      this.showTabTable(this.carPositionReq)
    },
    /** 切换tab */
    handleClick (tab) {
      if (this.buttonFlag === 1) {
        this.enlarge()
      }
      this.$nextTick(() => {
        this.$refs[tab.name].computeHeight()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-table {
  position: relative;
  outline: none;
  user-select: none;

  .monitoringTab {
    background: #fff;
    padding-bottom: 2px;
    .el-table td {
      padding: 5px 0 !important;
    }
    .el-tabs__content {
      padding: 0 20px;
    }
    .el-tabs__item {
      height: 32px;
      line-height: 32px;
    }
  }
  @include themify() {
    /** 在线 */
    .carOnline {
    }
    .hideBox {
      position: absolute;
      right: 0;
      top: -38px;
      display: flex;
      width: 80px;
      z-index: 100;
      justify-content: space-around;
      span {
        width: 74px;
        height: 24px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-size: 5rem;
        background-position: center;
        background-repeat: no-repeat;
        color: themed("b4");
        border-color: themed("b3");
        border-radius: 6px 6px 0px 0px;
      }
    }
  }
}
</style>
<style >
.el-tabs__content {
  background: #fff;
}
</style>
