
<template>
  <div class="vehicle-seting-page">
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      :rules="formRules"
      label-position="left"
      class="demo-form dialog-form"
    >
      <!-- 已选车辆终端 -->
      <el-form-item label="" v-if="!isBatch">
        <el-row>
          <el-col :span="12">车牌号：{{ data.name }}</el-col>
          <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
        </el-row>
      </el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
        v-if="isBatch"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item
        label="选择车辆："
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
        v-if="isBatch"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
      <!-- 客运车辆禁行设置 -->
      <!-- <el-form-item label="时间段：" prop="rangeTime">
        <el-time-picker
          is-range
          v-model="form.rangeTime"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          format="HH:mm"
        >
        </el-time-picker>
      </el-form-item>
       <el-form-item label="持续时间(分钟)：" prop="continueTime">
        <el-input-number
          v-model="form.continueTime"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item label="速度阈值(km/h)：" prop="speedCoach">
        <el-input-number
          v-model="form.speedCoach"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item> 
       <el-form-item label="重复">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin-right: 15px"></div>

        <el-checkbox-group
          v-model="checkedDates"
          @change="handlecheckedDatesChange"
        >
          <el-checkbox
            v-for="dateTime in dateOptions"
            :label="dateTime"
            :key="dateTime"
            >{{ dateTime }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item> -->
      <el-form-item label="    ">
        <el-row>
          <el-col :span="12">
            <el-form-item label-width="0">
              <el-checkbox
                v-model="form.connectionConfig"
                label="接驳"
              ></el-checkbox>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label-width="0">
              <el-checkbox
                v-model="form.nightProhibition"
                label="夜间禁行"
              ></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 超速阈值设置 -->
      <el-form-item>
        <el-button size="small" @click="cancle()">取消</el-button>
        <el-button type="primary" size="small" @click="save()">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { returnVideoArr } from '@/common/utils/index'
import {
  queryProhibition,
  saveProhibition
} from '@/api/lib/api.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'



export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 */
    isBatch: {
      type: Boolean,
      default: false
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    number: {
      type: Number,
      default: 300
    }
  },
  watch: {},
  mounted () { },
  data () {
    return {
      vehIdList: [],
      form: {
        vehIds: [],
        companyIds: [],
        rangeTime: [new Date(2016, 9, 10, 2, 0), new Date(2016, 9, 10, 5, 0)],
        continueTime: 5, //持续时间
        speedCoach: 10, //速度阀值
        isConnection: 0, //是否接驳  1表示接驳  0表示不接驳
        nightProhibition: false,
        connectionConfig: false,
      },
      checkAll: true,
      checkedDates: Array.from({ length: 31 }, (_, i) => `${i + 1}日`),
      isIndeterminate: false,

      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],
        continueTime: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        speedCoach: [{ required: true, message: '不能为空', trigger: 'blur' }],
        nightProhibition: [{ required: true, message: '不能为空', trigger: 'blur' }],
        connectionConfig: [{ required: true, message: '不能为空', trigger: 'blur' }],
        rangeTime: [
          { required: true, message: '请选择时间段', trigger: 'blur' }
        ]
      },
      dateOptions: Array.from({ length: 31 }, (_, i) => `${i + 1}日`)
    }
  },

  methods: {
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      this.form.vehIds = [...val]
    },

    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },
    /**保存数据 */
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {
            nightProhibition: this.form.nightProhibition ? 0 : 1,
            connectionConfig: this.form.connectionConfig ? 0 : 1,
          }
          if (this.isBatch) {
            data.vehicleIds = this.form.vehIds
            data.companyIds = this.form.companyIds
          } else {
            data.vehicleIds = [Number(this.data.id.replace("v", ""))]
          }
          saveProhibition(data).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.$emit('close', true)
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    cancle () {
      this.$emit('close', true)
    },

    /**全选日期  处理成可用格式 */
    handleCheckAllChange (val) {
      this.checkedDates = val ? this.dateOptions : []
      this.isIndeterminate = false
    },
    /**日期  处理成可用格式 */
    handlecheckedDatesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.dateOptions.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.dateOptions.length
    },
    /**当数据回显没有值时,重置表单 */
    resetForm () {
      this.form.rangeTime = [
        new Date(2016, 9, 10, 0, 0),
        new Date(2016, 9, 10, 23, 59)
      ]
      this.checkedDates = []
      this.form.repetition = ''
      this.form.stime = ''
      this.form.stimecoach = ''
      this.form.nightProhibition = false
      this.form.connectionConfig = false
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (!this.isBatch) {
      queryProhibition({ vehicleId: Number(this.data.id.replace("v", "")) }).then(res => {
        if (res.code === 1000) {
          this.form.nightProhibition = res.data.nightProhibition === 0
          this.form.connectionConfig = res.data.connectionConfig === 0
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.vehicle-seting-page {
  .el-checkbox-group {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    label {
      width: 8%;
      margin-right: 20px !important;
      margin-left: 0 !important;
    }
  }
}
</style>
