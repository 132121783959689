
<template>
  <div class="map-content" style="height: calc(100vh - 100px)">
    <!-- 地图 -->
    <div id="container" class="monitoringMap"></div>
    <div class="mapHintCard" v-show="clearAll">
      <div class="hintItem" v-for="(item, i) in hintOptions" :key="i">
        <p>
          <img :src="item.url" />
        </p>
        <p>{{ item.val }}</p>
      </div>
    </div>
    <!-- 地图 -->

    <!-- 顶部tab点击按钮 轨迹回放功能按钮-->
    <div class="alignCenterBox search-module-top floatR rightOperationBox">
      <el-menu
        :default-active="activeIndex"
        ref="elMenu"
        class="el-menu-operation"
        mode="horizontal"
        @select="handleSelect"
        background-color="#fff"
        text-color="#333333"
        active-text-color="#336FFE"
      >
        <el-menu-item index="0">
          <i class="iconfont its_ditumoshi"></i>
          <span slot="title">地图模式</span>
        </el-menu-item>
        <el-menu-item index="1">
          <i class="iconfont its_shipin"></i>
          <el-badge :is-dot="showRealVideoCard">视频模式</el-badge>
        </el-menu-item>
        <el-menu-item
          index="2"
          v-if="$store.state.menu.nowMenuList.indexOf('区域查车') >= 0"
        >
          <i class="iconfont its_icon_quyuchache"></i>
          <span slot="title">区域查车</span>
        </el-menu-item>
        <el-menu-item
          index="3"
          v-if="$store.state.menu.nowMenuList.indexOf('轨迹回放') >= 0"
        >
          <i class="iconfont its_guijihuifang"></i>
          <span slot="title">轨迹回放</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="iconfont its_lishishipin"></i>
          <el-badge :is-dot="showhistoryVideoCard">历史视频</el-badge>
        </el-menu-item>
        <el-menu-item index="5">
          <i class="iconfont its_gengduogongju"></i>
          <span slot="title">更多工具</span>
          <i class="iconfont its_caret-down more-icon"></i>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- 轨迹回放选择器 -->
    <div class="alignCenterBox trailPlayBox" v-if="!clearAll">
      <div>
        <span class="title replay-time">开始时间：</span>
        <el-date-picker
          class="marginR sm-time-label"
          size="small"
          v-model="form.beginTime"
          type="datetime"
          placeholder="选择日期时间"
          width="200"
        ></el-date-picker>
      </div>
      <div>
        <span class="title replay-time">结束时间：</span>
        <el-date-picker
          class="sm-time-label"
          size="small"
          v-model="form.endTime"
          type="datetime"
          placeholder="选择日期时间"
        ></el-date-picker>
      </div>
      <el-button
        class="marginR smFont"
        type="primary"
        size="small"
        @click="trailSearch"
        >查询</el-button
      >
      <el-button class="marginR20" size="small" @click="quitTrailReplay"
        >取消</el-button
      >

      <div class="trailBtn" v-if="afterSearch">
        <i
          @click="playTrail"
          title="播放"
          v-if="btnFlag && showBtn"
          class="iconfont its_icon_bofang"
        ></i>
        <i
          @click="resumeTrail"
          title="继续"
          v-if="!btnFlag && showBtn"
          class="iconfont its_icon_bofang"
        ></i>
        <i
          @click="pauseTrail"
          title="暂停"
          v-if="!btnFlag && !showBtn"
          class="iconfont its_icon_zanting"
        ></i>
        <i
          @click="stopTrail"
          title="停止"
          class="iconfont its_icon_tingzhi"
        ></i>
        <div class="replaySpeed">
          <el-slider
            style="width: 100px"
            v-model="replaySpeed"
            @change="changeReplaySpeed"
            :show-tooltip="false"
            :marks="marks"
          ></el-slider>
        </div>
        <!-- <i
          @click="showLineTable"
          :disabled="!$store.state.menu.nowMenuList.add"
          class="iconfont iconmap_icon_savelie"
          style="color:#00644B"
        ></i>-->
      </div>
      <div class="replay-box" v-if="afterSearch">
        <div>
          <span>定位时间：</span>
          <span>{{ replayform.gt ? replayform.gt : "--" }}</span>
        </div>
        <div>
          <span>行驶速度：</span>
          <span>{{ replayform.v ? replayform.v : "0" }}</span
          >公里/小时
        </div>
        <div>
          <span>行驶里程：</span>
          <span>{{ replayform.m ? replayform.m : " 0" }}</span
          >公里
        </div>
        <div>
          <span>相对里程：</span>
          <span>{{ replayform.rm ? replayform.rm : "0" }}</span
          >公里
        </div>
        <div>
          <span>车辆状态：</span>
          <span>{{ replayform.c ? replayform.c : "--" }}</span>
        </div>
      </div>
    </div>

    <!-- 地图功能组件 -->
    <tool-bar
      :showTool="showTool"
      @toggleOverViewShow="toggleOverViewShow"
      @toggleChinaMap="toggleChinaMap"
      @toggleSatelliteShow="toggleSatelliteShow"
      @toggleRoadnetShow="toggleRoadnetShow"
      @toggleDistance="toggleDistance"
      @setMapFeatures="setMapFeatures"
      @clearMap="clearMap"
      @addMarker="addMarker"
      @renderMarker="renderMarker"
    ></tool-bar>

    <!-- 区域内车辆 -->
    <el-dialog
      title="区域内车辆"
      v-if="outerVisible"
      :visible.sync="outerVisible"
      width="80%"
      @close="outerVisibleClose"
    >
      <car-table
        :location="location"
        @outerVisibleClose="outerVisibleClose"
      ></car-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStartDate,
  getCurentDate,
  formatDate,
  getTimeIsQualified,
  checkTimeLimitDay,
  checkLimitDate,
  compareAscSort
} from '@/common/utils/index'
import toolBar from '../toolBar/toolBar.vue'
import carTable from '../carTable/carTable.vue'
import getPlace from '@/common/utils/getPlace.js'
import { YHTools } from '@/assets/js/Tools.js'
import {
  getHistoryTrackView,
  getVehicleGroupTree,
  addSpotCheck
} from '@/api/lib/refreshAlarm.js'

export default {
  components: {
    'car-table': carTable,
    toolBar
  },
  props: {
    carPosition: {
      type: Array,
      default: function () {
        return []
      }
    },
    locatePoint: {
      type: Object,
      default: function () {
        return {}
      }
    },
    /** 运营监控展示车辆位置的请求参数 */
    carPositionReq: {
      type: Array,
      default: function () {
        return []
      }
    },
    /** 轨迹回放停车未熄火的请求参数 */
    reqData: {
      type: Array,
      default: function () {
        return []
      }
    },
    /** 当前系统参数 */
    system: {
      type: [Number, String]
    },
    /** 实时视频是否已显示 */
    showRealVideoCard: {
      type: Boolean,
      default: false
    },
    /** 历史视频是否已显示 */
    showhistoryVideoCard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      map: null,
      cluster: null,
      labelCluster: null,
      activeIndex: '0', //菜单index
      timer_bus: null, // 动态车辆定时器
      reqstatus: 0, // 定时器请求次数
      markers_bus: [], // 车辆
      marker_label: [], // 车辆标注text
      title: '',
      editorColor: {
        strokeColor: '#059370',
        fillColor: '#9ed09d',
        markerStrokeColor: '#80d8ff'
      },
      clusterImg: [
        require('@/assets/images/map/48.png'),
        require('@/assets/images/map/64.png'),
        require('@/assets/images/map/84.png'),
        require('@/assets/images/map/100.png')
      ],
      iconUrl: [
        require('@/assets/images/map/car0.png'), //离线
        require('@/assets/images/map/car6.png'), //空车
        require('@/assets/images/map/car6.png'), //重车
        require('@/assets/images/map/car6.png'), //停运
        require('@/assets/images/map/car4.png'), //异常
        require('@/assets/images/map/car5.png'), //行驶
        require('@/assets/images/map/car6.png') //在线
      ],
      trailIcon: [
        require('@/assets/images/map/trailStart.png'), //起点
        require('@/assets/images/map/trailEnd.png'), //终点
        require('@/assets/images/map/trail.png') //停车点
      ],
      driftIcon: [
        require('@/assets/images/map/driftStart.png'), //起点
        require('@/assets/images/map/driftEnd.png'), //终点
        require('@/assets/images/map/drift.png') //漂移点
      ],
      hintOptions: [
        { url: require('@/assets/images/map/car6.png'), val: '在线' },
        { url: require('@/assets/images/map/car4.png'), val: '报警' },
        { url: require('@/assets/images/map/car5.png'), val: '行驶' },
        { url: require('@/assets/images/map/car0.png'), val: '离线' }
      ],

      options: [
        {
          key: 1,
          value: '分段限速'
        },
        {
          key: 2,
          value: '路线偏移'
        }
      ],
      tipinput: '',
      endtipinput: '',
      addStatus: false,
      locations: [],
      startPlace: '',
      endPlace: '',
      scale: null,
      overView: null,
      toolBar: null,
      mouseTool: null,
      drawTool: null,
      radio: null,
      ruler: null,
      overlays: [],
      rectangle: {},
      satelliteLayer: null,
      roadNetLayer: null,
      outerVisible: false,
      navgtr: null,
      marker: null,
      addForm: {
        title: ''
      },
      lineform: {
        pathName: '',
        pathType: 1,
        addField: null,
        marks: '',
        iVehGroup: []
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      rules: {
        title: [{ required: true, message: '请输入设施名称', trigger: 'blur' }]
      },
      form: {
        beginTime: getStartDate(),
        endTime: getCurentDate(),
        plate: '', // 当前选中车辆名称
        vehId: '', // 当前选中车辆id
        currentPage: 1,
        pageSize: 99999
      },
      outerVisible: false, //显示区域查车
      trailArr: [], // 轨迹点数据
      markers: [],
      path: [],
      passedLength: 0,
      passedPolyline: {}, //最原始
      passline: [], //通过路径的数组
      passline1: [], //未通过路径数组
      polyline1: [], //重绘通过路径轨迹的集合
      noFlameoutVisible: false, // 显示停车未熄火查询表格
      filterMaskdataVisible: false, // 显示屏蔽数据筛选表格
      trailSearchTime: 5, // 轨迹回放可查询天数
      pathDetails: [], // 轨迹回放
      pointArr: [], // 轨迹回放
      showBtn: true, // 轨迹回放
      btnFlag: true, // 轨迹回放
      replaySpeed: 0,
      searchObj: {},
      clearAll: true,
      afterSearch: false,
      index: '',
      sliderValue: 50,
      replayform: {},
      marks: {
        0: '快',
        100: '慢'
      },
      loading: false,
      showTool: false,
      showBoxDetail: false,
      // -------------------------------------------------------------------------------
      driftMapStatus: true, // 显示漂移
      deviationArr: [], // 漂移线路Arr
      deviationPolyline: [], // 漂移线路
      driftMarker: [], // 漂移点
      driftText: [] // 漂移提示信息
    }
  },
  methods: {
    initMap () {
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        resizeEnable: true,
        expandZoomRange: true,
        // center: this.$store.state.user.lnglat,
        features: ['bg', 'road', 'building', 'point'],
        zoom: 12
      })
      this.map = map
    },
    /* 运营监控___地图上展示车辆位置 展示车辆 定时请求移动车辆 */
    showBus (vehids) {
      let self = this
      if (self.timer_bus) {
        self.clearBusMarker()
      }
      // 取消选中
      if (vehids.length === 0) {
        self.clearBusMarker()
        return
      }
      let busTimerFn = function () {
        self.markers_bus = []
        self.marker_label = []
        getVehicleGroupTree({
          id: null,
          ids: vehids.join(','),
          system: self.system,
          checked: true,
          keyWord: ''
        }).then((res) => {
          if (res.data.length === 0) {
            self.$message.warning('暂无车辆实时数据')
            self.clearBusMarker()
          } else {
            self.renderCarMarker(res.data)
          }
        })
      }
      busTimerFn()
      self.reqstatus += 1
      self.timer_bus = setInterval(() => {
        busTimerFn()
      }, 10000)
    },
    /** 拼装车辆信息 */
    renderCarMarker (points) {
      let self = this

      self.markers_bus = []
      self.marker_label = []
      for (var i = 0; i < points.length; i += 1) {
        let marker = new AMap.Marker({
          position: points[i]['lnglat'],
          icon: new AMap.Icon({
            size: new AMap.Size(40, 26), // 图标尺寸
            image: self.iconUrl[points[i].style],
            imageSize: new AMap.Size(40, 26)
          }),
          zIndex: 999,
          position: points[i]['lnglat'],
          angle: points[i].d + 270,
          size: new AMap.Size(40, 26), // 图标尺寸
          offset: new AMap.Pixel(-16, -16)
        })
        let id = points[i].id

        marker.on('click', function (e) {
          // 展示当前点击车辆信息
          self.$emit('showCarDetail', `v${id}`)
        })
        self.markers_bus.push(marker)
        //text makerMove
        let v = points[i].v != null ? `${points[i].v}km/h` : '无'
        let content = `<div>
                            <p style="color:#059370">${points[i].cph}</p>
                            <p>${v}</p>
                           </div>`
        let text = new AMap.Text({
          position: points[i].lnglat,
          text: content,
          offset: new AMap.Pixel(0, -52),
          style: {
            'font-size': '14px',
            border: 'none',
            padding: '6px 10px',
            'background-color': '#f6f6f9',
            'box-shadow': '0px 2px 8px 0px rgba(200, 201, 204, 0.5)',
            'line-height': '20px'
          }
        })
        text.on('click', function (e) {
          // 展示当前点击车辆信息
          self.$emit('showCarDetail', `v${id}`)
        })
        self.marker_label.push(text)
      }
      self.addCluster()
    },

    /** 显示车辆聚合信息 */
    addCluster (tag) {
      let self = this
      if (self.cluster) {
        self.cluster.setMap(null)
        self.labelCluster.setMap(null)
      }
      //自定义图标
      var sts = [
        {
          textColor: '#fff',
          url: self.clusterImg[0],
          size: new AMap.Size(48, 48),
          offset: new AMap.Pixel(-24, -24)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[1],
          size: new AMap.Size(64, 64),
          offset: new AMap.Pixel(-32, -32)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[2],
          size: new AMap.Size(84, 84),
          offset: new AMap.Pixel(-42, -42)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[3],
          size: new AMap.Size(100, 100),
          offset: new AMap.Pixel(-50, -50)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[3],
          size: new AMap.Size(100, 100),
          offset: new AMap.Pixel(-50, -50)
        }
      ]
      var labelStyle = [
        {
          textColor: '#fff',
          url: self.clusterImg[0],
          size: new AMap.Size(0, 0),
          offset: new AMap.Pixel(-24, -24)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[1],
          size: new AMap.Size(0, 0),
          offset: new AMap.Pixel(-32, -32)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[2],
          size: new AMap.Size(0, 0),
          offset: new AMap.Pixel(-42, -42)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[3],
          size: new AMap.Size(0, 0),
          offset: new AMap.Pixel(-50, -50)
        },
        {
          textColor: '#fff',
          url: self.clusterImg[3],
          size: new AMap.Size(0, 0),
          offset: new AMap.Pixel(-50, -50)
        }

      ]
      self.cluster = new AMap.MarkerClusterer(self.map, self.markers_bus, {
        styles: sts,
        gridSize: 80
      })
      self.labelCluster = new AMap.MarkerClusterer(self.map, self.marker_label, {
        styles: labelStyle,
        gridSize: 100
      })
      /**单个点聚集到中心 */
      if (self.markers_bus.length === 1) {
        self.map.setFitView()
        self.map.setZoomAndCenter(14, self.markers_bus[0].getPosition())
      }
    },
    /** 工具栏 */
    handleSelect (key, keyPath) {
      //重置工具栏
      this.toolReset()
      let flag = false
      switch (key) {
        case '1':
          //实时视频
          flag = true
          break
        case '4':
          // 历史视频
          flag = true
          break
        case '5':
          //更多工具
          this.moreTollbar()
          break
        case '2':
          //区域查车
          this.addRectangle()
          break
        case '3':
          //轨迹回放
          this.showTrailReplay()
          break

        default:
          break
      }
      if (key === '5') return
      this.$emit('showVideoCard', flag, key)
    },
    //设置当前menu选中菜单
    setActiveIndex (key) {
      this.$refs.elMenu.activeIndex = key
    },
    /* 轨迹回放 路线查询 查询事件 */
    trailSearch (startTime, endTime) {
      if (this.reqData.length === 0) {
        this.$message.warning('请先选择一辆车')
      } else if (this.reqData.length > 1) {
        this.$message.warning('只能选择一辆车')
      } else if (!this.showBtn || !this.btnFlag) {
        this.$message.warning('请先停止轨迹回放')
      } else {
        if (endTime) {
          this.form.beginTime = startTime
          this.form.endTime = endTime
        }
        let data = { ...this.form }
        if (
          !checkTimeLimitDay(
            this.form.beginTime,
            this.form.endTime,
            this.trailSearchTime
          )
        ) {
          this.$message.warning(`查询时间范围不超过${this.trailSearchTime}天！`)
        } else {
          if (
            !getTimeIsQualified(this.form.beginTime, this.form.endTime, true)
          ) {
            return false
          }

          data.beginTime = formatDate(data.beginTime)
          data.endTime = formatDate(data.endTime)
          data.vehId = this.reqData[0].id.replace('v', '')
          let par = {
            currentPage: 1,
            pageSize: 10,
            system: 0,
            beginTime: data.beginTime,
            endTime: data.endTime,
            vehId: data.vehId
          }
          this.getHistoryTrack(par) // 获取轨迹线路数据
          this.getHistoryTable(par) // 轨迹线路表格

          this.addSpotCheck(data.vehId) //调用添加抽查信息
        }
      }
    },
    /* 获取轨迹详情 */
    getHistoryTable (obj) {
      // this.$emit('resetTableParams') // 重置下拉加载参数
      let data = {
        ...obj,
        pageTime: obj.beginTime
      }
      delete data.name
      this.$emit('showHistoryTable', data)
    },
    /* 获取轨迹数据 */
    getHistoryTrack (obj) {
      let self = this
      self.loading = true
      self.clearBusMarker()
      getHistoryTrackView(obj).then((res) => {
        if (res.data && res.data.length !== 0) {
          self.afterSearch = true
          var arr = []
          var objArr = []
          // let pre = null // 前一个点
          // let secondArray = [] // 偏移线路暂存
          var deviationArr = [] // 偏移线路点
          var positions = [res.data[0]] // 轨迹de开始，停车，结束点
          for (let i = 0; i < res.data.length; i++) {
            const element = res.data[i]
            arr.push([element.lg, element.lt])
            objArr.push({
              longitude: element.lg,
              latitude: element.lt
            })
            if (element.pt && (i !== 0 || i !== res.data.length - 1)) {
              positions.push(element)
            }
            // if (pre == null) {
            //   pre = element
            // } else {
            //   if (pre.state === 0) {
            //     if (element.state === 1) {
            //       secondArray.push(pre)
            //       secondArray.push(element)
            //     }
            //   } else {
            //     if (element.state === 0) {
            //       secondArray.push(element)
            //       deviationArr.push(secondArray)
            //       secondArray = []
            //     } else {
            //       secondArray.push(element)
            //     }
            //   }
            //   pre = element
            // }
          }
          if (res.data.length > 1) {
            positions.push(res.data[res.data.length - 1])
          }
          self.trailArr = [...res.data] // 播放轨迹表单显示
          self.pointArr = arr // 轨迹线路
          self.pathDetails = objArr // 点击保存线路
          self.deviationArr = deviationArr // 轨迹漂移
          self.initPathSimplifier()
          self.drawLine(self.pointArr, positions, obj.name, deviationArr) // 展示线路  第二个参数:地图是否setFitView
          self.loading = false
        } else {
          self.trailArr = [] // 播放轨迹表单显示清空
          self.pointArr = [] // 轨迹线路清空
          self.$message({
            message: '暂无轨迹数据',
            type: 'warning'
          })
          self.loading = false
        }
      })
    },

    /* 播放轨迹路线 */
    playTrail () {
      if (this.pointArr.length !== 0) {
        this.clearAll = false
        this.showBtn = false
        this.btnFlag = false
        this.navgtr.setSpeed(100 * (101 - this.replaySpeed))
        this.navgtr.start()
        // this.$emit('showTrailReplayNotice', this.clearAll)
        // this.ivewMap()
      } else {
        this.$message.warning('请先查询线路')
      }
    },

    /* 暂停 */
    pauseTrail () {
      this.showBtn = true
      this.navgtr.pause()
    },

    /* 停止 */
    stopTrail () {
      this.btnFlag = true
      this.showBtn = true
      this.navgtr.stop()
      this.initPathSimplifier()
      this.$emit('showTrailReplayNotice', true)
    },

    /* 恢复 */
    resumeTrail () {
      this.showBtn = false
      this.navgtr.resume()
    },

    /* 切换速度 */
    changeReplaySpeed (val) {
      this.navgtr.setSpeed(100 * (101 - val))
    },

    /** 点击轨迹回放 */
    showTrailReplay () {
      this.clearAll = false
      this.deviationPolyline = [] // 漂移
      this.driftMarker = [] // 漂移
      this.driftText = [] // 漂移
      this.deviationArr = [] // 漂移
      this.driftMapStatus = true
      this.$emit('showTrailReplay', this.clearAll)
    },

    /** 点击退出轨迹回放 */
    quitTrailReplay () {
      this.clearAll = true
      this.clearBusMarker()
      this.afterSearch = false
      this.trailArr = [] // 播放轨迹表单显示清空
      this.pointArr = [] // 轨迹线路清空
      if (window.pathSimplifierIns) {
        window.pathSimplifierIns.setData([]) // 轨迹巡航清空
      }
      this.pathDetails = [] // 点击保存线路
      this.showBtn = true
      this.btnFlag = true
      this.showBus(this.carPositionReq) // 显示选中的车辆
      this.$refs.elMenu.activeIndex = '0'
      this.$emit('showTrailReplay', this.clearAll)
    },
    /* 轨迹回放 绘制线路 */
    drawLine (arr, positions, veh, deviationArr) {
      let self = this
      // 清除地图
      let polylines = []
      // 只有第一次进来的时候将arr赋值给this.lineArr 同时获取剪裁后的点
      self.lineArr = arr
      for (var i = 0; i < arr.length - 1; i++) {
        if (self.trailArr[i].ac) {
          polylines.push(self.returnLine([arr[i - 1], arr[i]], 'red'))
        }
      }
      /** 起始点marker */
      positions.forEach((item, i) => {
        let marker = new AMap.Marker({
          icon: new AMap.Icon({
            image:
              i === 0
                ? self.trailIcon[i]
                : i === positions.length - 1
                  ? self.trailIcon[1]
                  : self.trailIcon[2],
            imageSize: new AMap.Size(24, 30),
            size: new AMap.Size(30, 30) // 图标尺寸
          }),
          size: new AMap.Size(30, 30), // 图标尺寸
          zIndex: i === 0 || i === positions.length - 1 ? 200 : 100,
          position: [item.lg, item.lt]
        })

        marker.on('click', function (e) {
          let time = ''
          let timeDiff = ''
          let date = ''
          if (i !== 0 && i !== positions.length - 1) {
            time = item.pt.split('_')
            timeDiff = new Date(time[1]).getTime() - new Date(time[0]).getTime()
            date = YHTools.FormatTimeBySeconds(timeDiff / 1000, true)
              ? YHTools.FormatTimeBySeconds(timeDiff / 1000, true)
              : '0秒'
          }

          let area = ''
          getPlace(item.lg, item.lt, true).then((res) => {
            if (res) {
              area =
                res.province +
                res.city +
                res.district +
                res.street +
                res.township
            }
            let content =
              i === 0 || i === positions.length - 1
                ? `<div >时间：<span>${item.gt}</span></div>`
                : `<div >开始时间：<span>${time[0]}</span></div>
              <div >结束时间：<span>${time[1]}</span></div>
              <div >停车时长：<span>${date}</span></div>
              <div >相对里程：<span>${item.rm}公里</span></div>
              `
            let infoWindow = new AMap.InfoWindow({
              content: `<div class="trailBoard">
            ${content}
            <div style="color:#01adff;">${area}</div>
            </div>
            `
            })
            infoWindow.open(self.map, e.lnglat)
          })
        })
        marker.setMap(self.map)
      })
      // 缩放地图到合适的视野级别
      self.map.setFitView(polylines)
    },
    /** 创建polyline对象 */
    returnLine (spliceArr, color) {
      var polyline = new AMap.Polyline({
        map: this.map,
        path: spliceArr,
        borderWeight: 1,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeStyle: 'solid',
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
        geodesic: true, // 绘制大地线
        zIndex: 50
      })
      return polyline
    },
    initPathSimplifier () {
      var self = this
      AMapUI.load(
        ['ui/misc/PathSimplifier', 'lib/$'],
        function (PathSimplifier, $) {
          if (!PathSimplifier.supportCanvas) {
            alert('当前环境不支持 Canvas！')
            return
          }
          if (window.pathSimplifierIns) {
            //通过该方法清空上次传入的轨迹
            window.pathSimplifierIns.setData([])
          }
          var pathSimplifierIns = new PathSimplifier({
            zIndex: 100,
            //autoSetFitView:false,
            map: self.map, //所属的地图实例
            getPath: function (pathData, pathIndex) {
              return pathData.path
            },
            getHoverTitle: function (pathData, pathIndex, pointIndex) {
              if (pointIndex >= 0) {
                // return '点：' + (pointIndex + 1) + '/' + pathData.path.length + '，时间' + self.trailArr[pointIndex].gt
                //   + '，行驶速度' + self.trailArr[pointIndex].v + '公里/小时，行驶里程' + self.trailArr[pointIndex].m
                //   + '公里，相对里程' + self.trailArr[pointIndex].rm + '公里 <br/> 车辆状态：' + self.trailArr[pointIndex].c;
                if (self.trailArr[pointIndex].ac) {
                  return (
                    pointIndex +
                    1 +
                    '：' +
                    self.trailArr[pointIndex].gt +
                    '，' +
                    self.trailArr[pointIndex].v +
                    '公里/小时，' +
                    self.trailArr[pointIndex].ac
                  )
                } else {
                  return (
                    pointIndex +
                    1 +
                    '：' +
                    self.trailArr[pointIndex].gt +
                    '，' +
                    self.trailArr[pointIndex].v +
                    '公里/小时'
                  )
                }
              }
            },
            renderOptions: {
              renderAllPointsIfNumberBelow: -1,
              pathTolerance: 0,
              keyPointTolerance: -1,
              pathLineStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              pathLineHoverStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              pathLineSelectedStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              dirArrowStyle: {
                stepSpace: 80,
                strokeStyle: '#ffffff',
                lineWidth: 2
              }
            }
          })
          window.pathSimplifierIns = pathSimplifierIns
          //设置数据
          pathSimplifierIns.setData([
            {
              name: '路线0',
              path: self.pointArr
            }
          ])
          pathSimplifierIns.setSelectedPathIndex(0)
          pathSimplifierIns.on('pointClick', function (e, info) {
            // console.log('Click: ' + self.trailArr[info.pointIndex].gt)
          })
          //对第一条线路（即索引 0）创建一个巡航器
          // let image = PathSimplifier.Render.Canvas.getImageContent(require('@/assets/images/map/car6.png'), onload, onerror)
          var pathNavigatorStyles = [
            {
              width: 18,
              height: 18,
              strokeStyle: '#0B4FD5',
              fillStyle: '#0B4FD5',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            },
            {
              width: 18,
              height: 18,
              strokeStyle: 'red',
              fillStyle: 'red',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            }
          ]
          function extend (dst) {
            if (!dst) {
              dst = {}
            }
            var slist = Array.prototype.slice.call(arguments, 1)
            for (var i = 0, len = slist.length; i < len; i++) {
              var source = slist[i]
              if (!source) {
                continue
              }
              for (var prop in source) {
                if (source.hasOwnProperty(prop)) {
                  dst[prop] = source[prop]
                }
              }
            }
            return dst
          }
          self.navgtr = null
          self.navgtr = pathSimplifierIns.createPathNavigator(0, {
            loop: false, //循环播放
            speed: 10100, //巡航速度，单位千米/小时
            pathNavigatorStyle: extend({}, pathNavigatorStyles[0])
            //  pathNavigatorStyle: {
            //   width:40,
            //   height: 26,
            //   initRotateDegree: 270,
            //   content: image, // 自定义巡航样式
            //   strokeStyle: null,
            //   fillStyle: null,//经过路径的样式
            //   pathLinePassedStyle: {
            //     lineWidth: 3,
            //     strokeStyle: '#AF5',
            //     dirArrowStyle: false
            //   }
            // }
          })
          self.navgtr.on('move', function (data, position) {
            let idx = position.dataItem.pointIndex //走到了第几个点
            self.replayform = self.trailArr[idx]
            //获取到pathNavigatorStyle的引用
            var pathNavigatorStyle = self.navgtr.getStyleOptions()
            //覆盖修改
            extend(
              pathNavigatorStyle,
              pathNavigatorStyles[self.trailArr[idx].ac === null ? 0 : 1]
            )
            //重新绘制
            // pathSimplifierIns.renderLater();
            // var msgInfo = {
            //   '状态': this.getNaviStatus(),
            //   '设定速度': this.getSpeed() + ' km/h',
            //   '总行进距离': Math.round(this.getMovedDistance() / 1000) + ' km'
            // };
          })
        }
      )
    },
    //更多工具
    moreTollbar () {
      if (this.showTool) {
        this.showTool = false
        this.$refs.elMenu.activeIndex = '0'
        this.handleSelect('0')
      } else {
        this.showTool = true
      }
    },
    /** 距离量算;测面积;区域车辆;不能同时使用 */
    clearCheckBox (that) {
      this.distanceShow = false
      this.areaShow = false
      this.rectangleShow = false
      this.map.clearMap()
      this.close()
    },
    loadMapTool () {
      let self = this
      self.map.plugin(
        [
          'AMap.PlaceSearch',
          'AMap.Autocomplete',
          'AMap.Scale',
          'AMap.OverView',
          'AMap.Driving'
        ],
        function () {
          self.scale = new AMap.Scale({
            visible: true
          })
          self.map.addControl(self.scale)

          self.overView = new AMap.OverView({
            visible: false
          })
          self.map.addControl(self.overView)

          self.mouseTool = new AMap.MouseTool(self.map)
          self.radios = document.getElementsByName('func') // 拉框放大/缩小
          for (var i = 0; i < self.radios.length; i += 1) {
            self.radios[i].onchange = function (e) {
              self.draw(e.target.value)
            }
          }
          self.drawTool = new AMap.MouseTool(self.map)
          self.drawradios = document.getElementsByName('draw') // 量算,画点/线/面/
          for (var g = 0; g < self.drawradios.length; g += 1) {
            self.drawradios[g].onchange = function (e) {
              self.draw(e.target.value)
            }
          }
          self.mouseTool.on('draw', function (e) {
            self.overlays.push(e.obj)
          })

          // 构造官方卫星、路网图层
          self.satelliteLayer = new AMap.TileLayer.Satellite()
          self.roadNetLayer = new AMap.TileLayer.RoadNet()
        }
      )
    },
    // 工具栏切换先清空
    toolReset () {
      // 视频清空
      // this.$emit('showVideoCard', false)
      // 更多工具清空
      if (this.$refs.elMenu.activeIndex !== '5') {
        this.showTool = false
      }
      // 区域查车清空
      this.location = []
      this.outerVisible = false
      this.clearBusMarker()
      this.rectangleShow = false
      this.map.setDefaultCursor('pointer')
      this.mouseTool.close(true)
      // 轨迹清空
      this.clearAll = true
      this.afterSearch = false
      this.trailArr = [] // 播放轨迹表单显示清空
      this.pointArr = [] // 轨迹线路清空
      if (window.pathSimplifierIns) {
        window.pathSimplifierIns.setData([]) // 轨迹巡航清空
      }
      this.pathDetails = [] // 点击保存线路
      this.showBtn = true
      this.btnFlag = true
      this.showBus(this.carPositionReq) // 显示选中的车辆
      // this.$emit('showTrailReplay', this.clearAll)
    },
    // 查询区域车辆dialog关闭时
    outerVisibleClose () {
      this.location = []
      this.outerVisible = false
      this.$refs.elMenu.activeIndex = '0'
      this.clearBusMarker()
      this.rectangleShow = false
      this.map.setDefaultCursor('pointer')
      this.mouseTool.close(true)
    },
    /** 清除地图上显示和缓存车辆marker数据 */
    clearBusMarker () {
      this.reqstatus = 0
      clearInterval(this.timer_bus)
      this.map.clearMap()
      if (this.cluster) this.cluster.setMap(null)
      if (this.labelCluster) this.labelCluster.setMap(null)
      this.marker_bus = []
      // this.map.remove(this.marker_label)
      this.marker_label = []
    },
    /** 清除showbus请求次数 */
    reloadStstus () {
      this.reqstatus = 0
    },
    /** 清除showbus定时器 */
    reloadtimerBus () {
      clearInterval(this.timer_bus)
    },
    //区域车辆
    addRectangle () {
      let self = this
      self.clearBusMarker()
      self.map.setDefaultCursor('crosshair')
      // 画矩形
      // 2020.11.12 young 后端要求区域查车画的矩形面积在xxx内，具体需要询问
      self.mouseTool.on('draw', function (e) {
        self.getPath(e.obj.getPath())
        // self.mouseTool.close(true);
      })
      self.rectangle = self.mouseTool.rectangle({
        strokeColor: '#FF33FF',
        strokeOpacity: 0.5,
        strokeWeight: 6,
        fillColor: '#1791fc',
        fillOpacity: 0.5,
        strokeStyle: 'dashed'
      })
    },
    getPath (arr) {
      let path
      path = {
        maxLongitude: arr.sort(compareAscSort('lng'))[3].lng,
        maxLatitude: arr.sort(compareAscSort('lat'))[3].lat,
        minLongitude: arr.sort(compareAscSort('lng'))[0].lng,
        minLatitude: arr.sort(compareAscSort('lat'))[0].lat
      }
      this.outerVisible = true
      this.location = path
    },
    //查询区域车辆dialog关闭时
    close () {
      this.location = []
      this.outerVisible = false
      this.clearBusMarker()
      this.rectangleShow = false
      this.mouseTool.close(true)
    },
    // 显示鹰眼
    toggleOverViewShow (data) {
      if (data) {
        this.overView.show()
        this.overView.open()
      } else {
        this.overView.hide()
      }
    },
    //全图
    toggleChinaMap (data) {
      this.map.setZoom(data)
    },
    //显示卫星图
    toggleSatelliteShow (data) {
      if (data) {
        this.map.add(this.satelliteLayer)
      } else {
        this.map.remove(this.satelliteLayer)
      }
    },
    //显示路网图
    toggleRoadnetShow (data) {
      if (data) {
        this.map.add(this.roadNetLayer)
      } else {
        this.map.remove(this.roadNetLayer)
      }
    },
    //距离量算、画线等鼠标功能
    toggleDistance (data, option) {
      if (data === 0) {
        this.mouseTool.close(true)
      } else if (data === 1) {
        this.clearCheckBox()
        this.mouseTool.rule(option)
      } else if (data === 2) {
        this.clearCheckBox()
        this.mouseTool.measureArea(option)
      } else if (data === 3) {
        this.mouseTool.rectZoomIn(option)
      } else if (data === 4) {
        this.mouseTool.rectZoomOut(option)
      } else if (data === 5) {
        this.mouseTool.marker({})
      } else if (data === 6) {
        this.mouseTool.polyline(option)
      } else if (data === 7) {
        this.mouseTool.polygon(option)
      } else if (data === 8) {
        this.mouseTool.rectangle(option)
      } else if (data === 9) {
        this.mouseTool.circle(option)
      }
    },
    //设置地图显示要素
    setMapFeatures (data) {
      this.map.setFeatures(data)
    },
    //清楚地图
    clearMap () {
      this.map.clearMap()
    },
    //实例化点标记(添加自定义设施)
    addMarker () {
      let self = this
      var marker = new AMap.Marker({
        icon: require('@/assets/images/map/poi-marker-default.png'),
        position: this.map.getCenter(),
        offset: new AMap.Pixel(-13, -30),
        draggable: true,
        cursor: 'move',
        raiseOnDrag: true
      })
      marker.setLabel({
        offset: new AMap.Pixel(0, -20), //设置文本标注偏移量
        content: "<div class='info'>点击标注,添加自定义设施</div>", //设置文本标注内容
        direction: 'right' //设置文本标注方位
      })
      this.marker = marker
      marker.setMap(this.map)
      //点击跳出弹框,写标注
      this.marker.on('click', function (e) {
        self.dialogFormVisible = true
        self.form = {
          latitude: e.lnglat.lat,
          longitude: e.lnglat.lng
        }
      })
    },
    //渲染marker
    renderMarker (locations) {
      locations.forEach((item, index) => {
        var marker = new AMap.Marker({
          icon: require('@/assets/images/map/poi-marker-default.png'),
          position: [item.longitude, item.latitude],
          offset: new AMap.Pixel(-13, -30),
          draggable: true,
          cursor: 'move',
          raiseOnDrag: true
        })
        marker.setLabel({
          offset: new AMap.Pixel(0, -20), //设置文本标注偏移量
          content: `< div class='info' > ${item.title}</div >`, //设置文本标注内容
          direction: 'right' //设置文本标注方位
        })
        marker.setMap(this.map)
      })
    },
    //添加抽查信息
    addSpotCheck (vehId) {
      addSpotCheck({
        type: 2,
        ids: `v${vehId}`,
        system: this.system
      }).then()
    }
  },
  created () { },
  mounted () {
    this.initMap()
    this.loadMapTool()
  },
  watch: {
    /** 点击轨迹回放表格中位置信息 显示到地图上 */
    locatePoint (val) {
      this.map.remove(this.markers)
      var marker = new AMap.Marker({
        position: [val.longitude, val.latitude],
        zIndex: 201
      })
      marker.setMap(this.map)
      this.markers.push(marker)
      let self = this
      marker.on('click', function (e) {
        let area = ''
        getPlace(val.longitude, val.latitude, true).then((res) => {
          if (res) {
            area =
              res.province + res.city + res.district + res.street + res.township
          }
          let content = `<div >时间：<span>${val.time}</span></div>`
          let infoWindow = new AMap.InfoWindow({
            content: `<div class=trailBoard>
            ${content}
            <div style=color:#059370;>${area}</div>
            </div>
            `
          })
          infoWindow.open(self.map, e.lnglat)
        })
      })
      self.map.setZoom(18)
      self.map.setCenter([val.longitude, val.latitude])
    }
  },
  beforeDestroy () {
    clearInterval(this.timer_bus)
  }
}
</script>

<style lang="scss" scoped>
@mixin search-module-top {
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 16px;
  padding: 0 2px;
  border-radius: 4px;
  height: 46px;
  background: #fff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(52, 52, 52, 0.2);
}
@mixin active {
  color: themed("b4") !important;
  background-color: #eaf0fe !important;
  border-radius: 78px !important;
  i {
    color: themed("b4") !important;
  }
}

.map-content {
  position: relative;
  @include themify() {
    .monitoringMap {
      width: 100%;
      height: 100%;
    }

    /**轨迹回放顶部操作栏 */
    .trailPlayBox {
      position: absolute;
      color: #333333;
      z-index: 10;
      background: #fff;
      width: 271px;
      top: 67px;
      right: 18px;
      padding: 15px;
      .trailBtn {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #f2f3f4;
        margin: 10px 0;
        .its_icon_bofang {
          color: themed("b4");
        }
        img {
          cursor: pointer;
        }
      }
      .sm-time-label {
        width: 185px !important;
        padding: 8px 0 !important;
        /deep/.el-input__icon {
          line-height: 50px;
        }
      }
      .el-button-group {
        .el-button--primary {
          margin-left: 10px;
          padding: 8px !important;
        }
      }
      .replay-box div {
        padding: 6px;
      }
    }
    /**图标展示 */
    .mapHintCard {
      position: absolute;
      bottom: 4.5vh;
      right: 10px;
      width: 340px;
      height: 62px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 2px 4px 0px rgba(52, 52, 52, 0.2);

      .hintItem {
        width: calc(25% - 5px);
        color: #333333;
        text-align: center;
      }
    }
    /**顶部菜单 */
    .search-module-top {
      @include search-module-top;

      .el-menu--horizontal {
        border-bottom: none;
        & > .el-menu-item {
          height: 32px;
          line-height: 32px;
          padding: 0 14.7px;
          border: none;
          .iconfont {
            padding-right: 8px;
          }
          .more-icon {
            padding-left: 8px;
            padding-right: 8px;
            float: right;
          }
        }
      }
      .el-menu-item:hover {
        @include active;
      }
      .el-menu-item.is-active {
        @include active;
      }
    }
  }
  /**顶部菜单 */
  @media screen and (max-width: 1366px) {
    .search-module-top {
      .el-menu--horizontal {
        & > .el-menu-item {
          padding: 0 14px !important;
        }
      }
    }
  }
  @media screen and (min-width: 1367px) and (max-width: 1600px) {
    .search-module-top {
      .el-menu--horizontal {
        & > .el-menu-item {
          padding: 0 17.2px !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
</style>
