export default {
  operationList: [
    // 部标设备指令
    {
      name: '点名',
      index: 'deviceCall',
      type: 'order',
      width: ''
    },
    {
      name: '文本信息下发',
      index: 'sendInformation',
      type: '',
      width: '30%'
    },
    {
      name: '设置运营线路',
      index: 'setOperationLine',
      type: 'newPage',
      width: '30%'
    },
    {
      name: '查询终端参数',
      index: 'QueryParameter',
      type: 'order',
      width: ''
    },
    {
      name: '设置终端参数',
      index: 'terminalParameter',
      type: 'newPage',
      width: '50%'
    },
    {
      name: '设置服务器参数',
      index: 'terminalIp',
      type: 'newPage',
      width: '50%'
    },

    {
      name: "终端升级",
      index: "terminalAttributes",
      type: 'newPage',
      width: '50%'
    },
    {
      name: "设置IC卡参数",
      index: "ICardset",
      type: '',
      width: '30%',
    },
    {
      name: "查看驾驶员",
      index: "driverManage",
      type: 'page',
      width: '',
    },
    {
      name: "下线时限配置",
      index: "carOfflineTime",
      type: '',
      width: '30%',
    },
    {
      name: "客运车辆禁行设置",
      index: "carDrivingHibit",
      type: '',
      width: '40%',
    },
    {
      name: "修改车辆信息",
      index: "addVehicle",
      type: '',
      width: '70%',
    },
    // { name: '屏蔽报警', index: 'setAlarmShield', type: 'newPage', width: '50%' },
    // { name: '关闭报警声音', index: 'closeVoice', type: 'newPage', width: '50%' }
  ]

}
