<template>
  <span class="tree-expand" :class="{ isCamera: DATA.type === 4 }">
    <span @click="nodeclick(STORE, DATA, NODE)" :class="treeClass">
      <span class="carIcon iconfont" :class="classObject"></span>
      <span>{{ DATA.name }} </span>
    </span>
    <span
      class="tree-video"
      v-if="
        (DATA.type === 3 &&
          (DATA.videos ? returnVideoArr(DATA.videos) : 0) > 0) ||
        DATA.type === 4
      "
    >
      <i
        class="carIcon iconfont"
        :class="videoObject"
        @click.stop="nodeVideo(STORE, DATA, NODE)"
      ></i>
    </span>
    <!-- 下发指令图标 -->
    <span class="tree-video" v-show="DATA.type == 3 && showInstructions">
      <operationPop :data="DATA" @nodeOperation="nodeOperation" />
    </span>
    <span class="tree-video" v-show="DATA.type == 4">
      <i
        class="carIcon iconfont"
        :class="checkObject"
        @click.stop="nodeCheck(STORE, DATA, NODE, $event)"
      ></i>
    </span>
  </span>
</template>

<script>
import pop from './components/pop'

export default {
  name: 'tree-render',
  props: {
    NODE: {
      type: Object
    },
    DATA: {
      type: Object
    },
    STORE: {
      type: Object
    },
    expand: {
      type: Boolean
    },
    showInstructions: {
      type: Boolean,
      default: true
    },
    /** 已选中车辆id */
    checkCarId: {
      type: [Number, String]
    }
  },
  components: {
    operationPop: pop
  },

  mounted() {
    this.$nextTick(function () {
      const isCameraDom = document.querySelectorAll('.isCamera')
      if (isCameraDom.length > 0) {
        isCameraDom.forEach((element) => {
          element.previousElementSibling.style.visibility = 'hidden'
        })
      }
    })
  },
  computed: {
    /** type参数(类型):0平台 1企业  2车队 3车辆 4摄像头 5不显示的数据 */
    /** ststus参数(车辆状态):0离线 1空车 2重车 3停运 4报警 5行驶 6在线*/
    /** acc参数(acc状态):0开 1关  */
    classObject: function () {
      return {
        its_icon_gongsi: this.DATA.type === 0 || this.DATA.type === 1,
        its_chezu: this.DATA.type === 2,
        // 离线
        'its_icon_taxi01 carOutline':
          this.DATA.type === 3 &&
          (this.DATA.status == null || this.DATA.status === 0),
        // 在线
        'its_icon_taxi01 carOnline':
          this.DATA.type === 3 && this.DATA.status && this.DATA.status !== 4,

        // 报警
        'its_icon_taxi01 carAlarm':
          this.DATA.type === 3 && this.DATA.status === 4,
        // 摄像头离线
        'its_shipin carOutline':
          this.DATA.type === 4 &&
          (this.DATA.status == null || this.DATA.status === 0),
        // 摄像头在线
        'its_shipin carOnline': this.DATA.type === 4 && this.DATA.status
      }
    },
    /** 实时视频 */
    videoObject: function () {
      return {
        'its_icon_bofang carOutline':
          this.DATA.status == null ||
          this.DATA.status === 0 ||
          this.DATA.acc === 1, // 车辆不在线/ACC关，默认灰色
        'its_icon_bofang carOnline':
          this.DATA.status !== 0 &&
          this.DATA.status !== null &&
          this.DATA.acc === 0 &&
          (!this.DATA.sa || this.DATA.sa.length === 0), // 车辆在线ACC开，可选中观看
        'its_icon_bofang1 carOnline':
          this.DATA.status !== 0 &&
          this.DATA.status !== null &&
          this.DATA.sa &&
          this.DATA.sa.length > 0 &&
          this.DATA.acc === 0
      }
    },
    /** 历史视频 */
    checkObject: function () {
      return {
        'its_lishishipin carOutline':
          this.DATA.status == null ||
          this.DATA.status === 0 ||
          this.DATA.acc === 1, // 车辆不在线
        'its_lishishipin carOnline':
          this.DATA.status &&
          this.DATA.acc === 0 &&
          this.checkCardId !== this.DATA.id, // 车辆未被选中
        'its_lishishipin carActive':
          this.DATA.status &&
          this.DATA.acc === 0 &&
          this.checkCarId === this.DATA.id // 车辆被选中
      }
    },
    /** 企业|车辆|摄像头宽度 */
    treeClass: function () {
      return {
        company: this.DATA.type === 0 || this.DATA.type === 1, // 企业
        carGroup: this.DATA.type === 2, // 车队
        car: this.DATA.type === 3, // 车辆
        camera: this.DATA.type === 4 // 摄像头
      }
    }
  },
  methods: {
    nodeVideo(s, d, n) {
      // 播放实时视频
      this.$emit('toPlayVideo', s, d, n)
    },
    nodeclick(s, d, n) {
      // 点击
      this.$emit('nodeclick', s, d, n)
    },
    nodeOperation(type, node) {
      // 车辆操作
      this.$emit('nodeOperation', type, node)
    },
    nodeEditFocus() {
      // 阻止点击节点的事件冒泡
    },
    nodeCheck(s, d, n) {
      // 播放历史视频
      this.$emit('nodeCheck', s, d, n)
    },
    /** 获取终端的摄像头个数 */
    returnVideoArr(data) {
      let self = this
      let index = data.indexOf(
        `${
          self.$store.state.vehicle.terminalType[
            sessionStorage.getItem('system')
          ]
        }-`
      )
      if (index === -1) return 0
      let params = data.substring(index)
      const videos = params.split(',')[0].split('_')
      return parseInt(videos[2])
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-expand {
  // overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  .company,
  .carGroup {
    flex: 4;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .car,
  .camera {
    width: 104px;
  }
  .tree-video {
    width: 1.5rem;
  }
  .carIcon,
  .tree-video i {
    display: inline-block;
    height: 1.2rem;
    width: 18px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  @include themify() {
    /** 在线 */
    .carOnline {
      color: themed('b4');
    }
    /** 离线 */
    .carOutline {
      color: #dcdedf;
    }
    /** 历史视频选中 */
    .carActive {
      color: themed('b6');
    }
    /** 报警 */
    .carAlarm {
      color: #d40000;
    }
  }
}
</style>
