<template>
  <div class="real-info" ref="realTimeInfo">
    <div
      ref="menu"
      class="copyMenu"
      @mouseleave="mousemoveMenu"
      @click="toCopy"
    >
      <div class="item">复制</div>
    </div>
    <el-table
      :row-class-name="rowBg"
      :data="tableData"
      style="width: 100%"
      class="no-blank"
      :height="tableHeight"
      stripe
      id="realTable"
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :width="90 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="车队"
        :width="120 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="type"
        :formatter="formatType"
        label="车辆类型"
        :show-overflow-tooltip="true"
        width="130"
      ></el-table-column>
      <el-table-column
        prop="gpsTime"
        label="刷新时间"
        :width="140 * screenCoefficient"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column
        prop="address"
        label="所在位置"
        width="260"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column
        prop
        label="速度方向"
        width="180"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            >速度:{{ scope.row.velocity ? scope.row.velocity : 0 }}km/h 方向:{{
              scope.row.angle ? scope.row.angle : '无'
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        :formatter="formatStatus"
        prop="status"
        label="车辆状态"
        width="90"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="定位状态"
        width="90"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.locate === 1 ? '未定位' : '已定位' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="component"
        label="部件状态"
        width="170"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column
        prop="deviceNos"
        label="终端编号"
        :width="120 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="runMile"
        label="里程数"
        :width="90 * screenCoefficient"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column
        prop="signInState"
        label="签登状态"
        width="90"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.signInState ? scope.row.signInState : '无'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="line"
        label="线路"
        width="100"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <!-- <el-table-column prop="taxiNo" label="终端类型" width="150" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="productCode" label="自编号" width="100" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column
        prop="driverName"
        label="驾驶员"
        width="70"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.driverName ? scope.row.driverName : '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="电话"
        width="120"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.phone ? scope.row.phone : '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="vehStatus"
        :formatter="formatRunState"
        label="运营维修状态"
        width="120"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <!-- <el-table-column prop="simNum" label="SIM卡号" width="150" :show-overflow-tooltip="true"></el-table-column> -->
      <!-- <el-table-column prop="addressIp" label="IP地址" width="150" :show-overflow-tooltip="true"></el-table-column> -->
    </el-table>
    <el-button
      type="primary"
      size="small"
      style="position: absolute; bottom: 5px; left: 5px"
      v-if="$store.state.menu.nowMenuList.indexOf('实时信息导出') > -1"
      @click="getXLS"
      >导出</el-button
    >
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDict, getCurent } from '@/common/utils/index'

import { queryDictsByCodes } from '@/api/lib/api.js'
import getXLS from '@/common/utils/exportXLS'
import { mapGetters } from 'vuex'

export default {
  props: {
    tableData: {
      type: Array,
      default: function () {
        return []
      }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  watch: {
    total: {
      handler(newVal, old) {
        if (newVal) {
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      screenCoefficient: 'screenCoefficient'
    })
  },
  data() {
    return {
      tableHeight: 0,
      row: null,
      typeObj: {},
      statusObj: {},
      runStateObj: {},
      currentPage: 1,
      pageSize: 10
    }
  },

  methods: {
    /** 导出 */
    getXLS() {
      getXLS('#realTable', `终端属性-${getCurent()}`)
    },
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.realTimeInfo.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - paginationHeight - 10
    },
    rowBg({ row, rowIndex }) {
      if (row.status === 4) {
        return 'redColor'
      }
      return ''
    },
    formatType(column, row, cellValue) {
      return this.typeObj[cellValue]
    },
    /** 状态转换 */
    formatStatus(column, row, cellValue) {
      return this.statusObj[cellValue]
    },
    formatRunState(column, row, cellValue) {
      return this.runStateObj[cellValue]
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'VETY,CLYYZT,CLZT' }).then((res) => {
        if (res.code === 1000) {
          this.typeObj = formatDict(res.data.VETY)
          this.statusObj = formatDict(res.data.CLYYZT)
          this.runStateObj = formatDict(res.data.CLZT)
        }
      })
    },
    /** 数据为空默认显示- */
    formatNull(row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },
    /** 当某一行被鼠标右键点击时会触发该事件 */
    handleContextmenu(row, column, event) {
      this.row = row
      event.preventDefault() // 做一些兼容性的处理
      const { menu } = this.$refs
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      menu.style.display = 'block'
      menu.style.left = event.clientX + 'px'
      // 当滑动滚动条时也能准确获取菜单位置
      menu.style.top = event.clientY + scrollTop - 20 + 'px'
      document.body.appendChild(menu)
    },
    /** 移出 */
    mousemoveMenu(event) {
      const { menu } = this.$refs
      menu.style.display = 'none'
      document.body.removeChild(menu)
    },
    /** 复制 */
    toCopy() {
      let {
        cph,
        companyName,
        type,
        gpsTime,
        address,
        signInState,
        telNo,
        velocity,
        angle,
        status,
        locate,
        runMile,
        driverName,
        phone,
        component,
        deviceNos,
        line,
        vehStatus
      } = this.row
      signInState = signInState || '无'
      velocity = velocity || 0
      angle = angle || 0
      driverName = driverName || '无'
      phone = phone || '无'
      type = this.formatType(null, null, type)
      status = this.formatStatus(null, null, status)
      vehStatus = this.formatRunState(null, null, vehStatus)

      locate = !locate ? '未定位' : '已定位'
      let content =
        cph +
        companyName +
        type +
        gpsTime +
        address +
        `速度:${velocity} km/h` +
        ` 方向:${angle}` +
        status +
        locate +
        component +
        telNo +
        runMile +
        signInState +
        line +
        driverName +
        phone +
        deviceNos +
        vehStatus

      let aux = document.createElement('input')
      aux.setAttribute('value', content)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand('copy')
      this.$message.success('复制成功')
      document.body.removeChild(aux)
    },
    /** 跳转第几页 */
    handleCurrentChange(v) {
      this.currentPage = v
      this.$emit('handleCurrentChange', v, this.pageSize)
    },

    /** 一页显示多少行 */
    handleSizeChange(v) {
      this.pageSize = v
      this.currentPage = 1
      this.$emit('handleCurrentChange', this.currentPage, v)
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.real-info {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .copyMenu {
    position: absolute;
    display: none;
    box-shadow: 2px 2px 2px #059370;
    z-index: 1000;
    background-color: #059370;
    width: 100px;
    .item {
      list-style: none;
      line-height: 30px;
      padding: 0 10px;
      margin: 0;
      font-size: 14px;
      color: #606266;
      cursor: pointer;
      outline: 0;
      &:hover {
        color: white;
      }
    }
  }
}
/deep/.el-table .redColor td {
  color: #ff1212;
}

/deep/.el-table thead th.is-leaf {
  line-height: 36px;
}
</style>
