<template>
  <div class="tool-bar">
    <div class="scaleBox" v-if="showTool">
      <div class="input-item" :class="'tool' + ChinaMapShow" @click="toggleChinaMap">
        <span>全图</span>
      </div>
      <div class="input-item" :class="'tool' + driveShow" @click="toggleDrive">
        <span>导航</span>
      </div>
      <div class="input-item" :class="'tool' + distanceShow" @click="toggleDistance">
        <span>测距</span>
      </div>
      <div class="input-item" :class="'tool' + areaShow" @click="toggleArea">
        <span>测面积</span>
      </div>
      <div class="input-item" :class="'tool' + overviewShow" @click="toggleOverViewShow">
        <span>鹰眼</span>
      </div>
      <div class="input-item" :class="'tool' + satelliteShow" @click="toggleSatelliteShow">
        <span>卫星图</span>
      </div>
      <div class="input-item" :class="'tool' + roadnetShow" @click="toggleRoadnetShow">
        <span>路网图</span>
      </div>
      <div class="input-item" :class="'tool' + featureShow" @click="toggleFeatureShow">
        <span>地图标注</span>
      </div>
      <div class="input-item" :class="'tool' + drawShow" @click="toggleDrawShow">
        <span>量算</span>
      </div>
      <div class="input-item" :class="'tool' + facilitiesShow" @click="toggleFacilitiesShow">
        <span>设施管理</span>
      </div>
      <div class="input-item" :class="'tool' + zoomShow" @click="toggleZoomShow">
        <span>拉框缩放</span>
      </div>
      <div class="input-item toolfalse" @click="printCanvas">
        <span>打印</span>
      </div>
      <div class="input-item toolfalse" @click="convertCanvasToImage">
        <span>截图</span>
      </div>
    </div>
    <div class="scaleBox input-card">
      <div
        class="input-item"
        v-if="featureShow"
        :class="'tool' + pointShow"
        style="top: 238px; right: 108px"
        @click="togglePointShow"
      >
        <span>标注</span>
      </div>
      <div
        class="input-item"
        v-if="drawShow"
        :class="'tool' + circleShow"
        style="top: 271px; right: 108px"
        @click="draw('circle')"
      >
        <span>画圆</span>
      </div>
      <div
        class="input-item"
        v-if="facilitiesShow"
        :class="'tool' + showLocationTable"
        style="top: 304px; right: 108px"
        @click="toLocationTable"
      >
        <span>编辑措施</span>
      </div>
      <div
        class="input-item"
        v-if="zoomShow"
        :class="'tool' + zoomOutShow"
        style="top: 340px; right: 108px"
        @click="draw('zoomOut')"
      >
        <span>拉框缩小</span>
      </div>
    </div>
    <div class="scaleBox input-card">
      <div
        class="input-item"
        v-if="featureShow"
        :class="'tool' + buildingShow"
        style="top: 238px; right: 215px"
        @click="toggleBuildingShow"
      >
        <span>建筑物</span>
      </div>
      <div
        class="input-item"
        v-if="drawShow"
        :class="'tool' + rectangleShow"
        style="top: 271px; right: 215px"
        @click="draw('rectangle')"
      >
        <span>画矩形</span>
      </div>
      <div
        class="input-item"
        v-if="facilitiesShow"
        :class="'tool' + addMarkerShow"
        style="top: 304px; right: 215px"
        @click="addMarker"
      >
        <span>添加设施</span>
      </div>
      <div
        class="input-item"
        v-if="zoomShow"
        :class="'tool' + zoomInShow"
        style="top: 340px; right: 216px"
        @click="draw('zoomIn')"
      >
        <span>拉框放大</span>
      </div>
    </div>
    <div class="scaleBox input-card">
      <div
        class="input-item"
        v-if="featureShow"
        :class="'tool' + roadShow"
        style="top: 238px; right: 320px"
        @click="toggleRoadShow"
      >
        <span>道路</span>
      </div>
      <div
        class="input-item"
        v-if="drawShow"
        :class="'tool' + polygonShow"
        style="top: 271px; right: 320px"
        @click="draw('polygon')"
      >
        <span>画多边形</span>
      </div>
    </div>
    <div class="scaleBox input-card">
      <div
        class="input-item"
        v-if="featureShow"
        :class="'tool' + bgShow"
        style="top: 238px; right: 428px"
        @click="toggleBgShow"
      >
        <span>区域面</span>
      </div>
      <div
        class="input-item"
        v-if="drawShow"
        :class="'tool' + polylineShow"
        style="top: 271px; right: 320px"
        @click="draw('polyline')"
      >
        <span>画线</span>
      </div>
    </div>
    <div class="scaleBox input-card">
      <div
        class="input-item"
        v-if="drawShow"
        :class="'tool' + markerShow"
        style="top: 271px; right: 420px"
        @click="draw('marker')"
      >
        <span>画点</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'pagination',
  props: {
    showTool: {
      type: Boolean
    }
  },
  data () {
    return {
      zoomOutShow: false,
      overviewShow: false, // 显示鹰眼
      satelliteShow: false, // 卫星
      roadnetShow: false, // 路网
      featureShow: false, // 地图标注
      bgShow: true, // 区域面
      roadShow: true, // 道路
      buildingShow: true, // 建筑物
      pointShow: true, // 标注
      drawShow: false, // 量算
      ChinaMapShow: false, // 全图
      fullScreenShow: false, // 全屏
      distanceShow: false, // 测距离
      areaShow: false, // 测面积
      rectangleShow: false, // 区域车辆
      driveShow: false, // 导航
      zoomShow: false, // 拉框
      zoomInShow: false, // 拉框放大
      zoomoutShow: false, // 拉框缩小
      markerShow: false, // 画点
      polylineShow: false, // 画线
      polygonShow: false, // 画多边形
      circleShow: false, // 画圆
      addMarkerShow: false, // 添加设施
      facilitiesShow: false, // 显示设施
      facilitiesMarkerShow: false, // 显示自定义设施
      showLocationTable: false,
      facilitiesMarkers: [],
      checkedOptions: ['bg', 'road', 'building', 'point'],
      Options: [
        // '区域面', '道路', '建筑物', '标注',
        {
          name: '区域面',
          value: 'bg'
        },
        {
          name: '道路',
          value: 'road'
        },
        {
          name: '建筑物',
          value: 'building'
        },
        {
          name: '标注',
          value: 'point'
        }
      ],
    }
  },
  computed: {},

  methods: {
    // 显示鹰眼
    toggleOverViewShow () {
      if (this.overviewShow) {
        this.overviewShow = false
        this.$emit('toggleOverViewShow', false)
      } else {
        this.overviewShow = true
        this.$emit('toggleOverViewShow', true)
      }
    },
    //全图
    toggleChinaMap () {
      if (this.ChinaMapShow) {
        this.ChinaMapShow = false
        this.$emit('toggleChinaMap', 5)
      } else {
        this.ChinaMapShow = true
        this.$emit('toggleChinaMap', 4)
      }
    },
    //导航
    toggleDrive () {
      if (this.driveShow) {
        this.driveShow = false
      } else {
        this.driveShow = true
      }
    },
    //显示卫星图
    toggleSatelliteShow () {
      if (this.satelliteShow) {
        this.satelliteShow = false
        this.$emit('toggleSatelliteShow', false)
      } else {
        this.satelliteShow = true
        this.$emit('toggleSatelliteShow', true)
      }
    },
    //显示路网图
    toggleRoadnetShow () {
      if (this.roadnetShow) {
        this.roadnetShow = false
        this.$emit('toggleRoadnetShow', false)
      } else {
        this.roadnetShow = true
        this.$emit('toggleRoadnetShow', true)
      }
    },
    //显示自定义设施
    toggleFacilitiesMakerShow () {
      if (this.facilitiesMarkerShow) {
        this.$emit('clearMap')
        this.facilitiesMarkerShow = false
      } else {
        this.facilitiesMarkerShow = true
        this.getFacilitiesMarkerList()
      }
    },
    //显示标注
    toggleFeatureShow () {
      if (this.featureShow) {
        this.featureShow = false
      } else {
        this.featureShow = true
        this.drawShow = false
        this.zoomShow = false
        this.facilitiesShow = false
      }
    },
    toggleBgShow () {
      if (this.bgShow) {
        this.checkedOptions.forEach(function (item, index, arr) {
          if (item == 'bg') {
            arr.splice(index, 1)
          }
        })
        this.bgShow = false
      } else {
        this.checkedOptions.push('bg')
        this.bgShow = true
      }
      this.setMapFeatures()
    },
    toggleRoadShow () {
      if (this.roadShow) {
        this.checkedOptions.forEach(function (item, index, arr) {
          if (item == 'road') {
            arr.splice(index, 1)
          }
        })
        this.roadShow = false
      } else {
        this.checkedOptions.push('road')
        this.roadShow = true
      }
      this.setMapFeatures()
    },
    toggleBuildingShow () {
      if (this.buildingShow) {
        this.checkedOptions.forEach(function (item, index, arr) {
          if (item == 'building') {
            arr.splice(index, 1)
          }
        })
        this.buildingShow = false
      } else {
        this.checkedOptions.push('building')
        this.buildingShow = true
      }
      this.setMapFeatures()
    },
    togglePointShow () {
      if (this.pointShow) {
        this.checkedOptions.forEach(function (item, index, arr) {
          if (item == 'point') {
            arr.splice(index, 1)
          }
        })
        this.pointShow = false
      } else {
        this.checkedOptions.push('point')
        this.pointShow = true
      }
      this.setMapFeatures()
    },
    //显示量算功能
    toggleDrawShow () {
      if (this.drawShow) {
        this.drawShow = false
      } else {
        this.featureShow = false
        this.drawShow = true
        this.zoomShow = false
        this.facilitiesShow = false
      }
    },
    // 显示设施功能
    toggleFacilitiesShow () {
      if (this.facilitiesShow) {
        this.facilitiesShow = false
      } else {
        this.featureShow = false
        this.drawShow = false
        this.zoomShow = false
        this.facilitiesShow = true
      }
    },
    // 拉框
    toggleZoomShow () {
      if (this.zoomShow) {
        this.zoomShow = false
        this.$emit('toggleDistance', 0)
      } else {
        this.featureShow = false
        this.drawShow = false
        this.zoomShow = true
        this.facilitiesShow = false
      }
    },
    // 添加点标记
    draw (type) {
      if (this[type + 'Show']) {
        this[type + 'Show'] = false
        this.$emit('toggleDistance', 0)
        this.drawTool.close(true)
      } else {
        this.zoomInShow = this.zoomOutShow = this.markerShow = this.polylineShow = this.polygonShow = this.rectangleShow = this.circleShow = false
        this[type + 'Show'] = true
        switch (type) {
          case 'zoomIn': {
            this.$emit('toggleDistance', 3, {
              strokeColor: '#80d8ff',
              fillColor: '#80d8ff',
              fillOpacity: 0.3
              //同 Polygon 的 Option 设置
            })
            break
          }
          case 'zoomOut': {
            this.$emit('toggleDistance', 4, {
              strokeColor: '#80d8ff',
              fillColor: '#80d8ff',
              fillOpacity: 0.3
              //同 Polygon 的 Option 设置
            })
            break
          }
          case 'marker': {
            this.$emit('toggleDistance', 5)
            this.drawTool.marker({
              //同Marker的Option设置
            })
            break
          }
          case 'polyline': {
            this.$emit('toggleDistance', 6, {
              strokeColor: 'black'
              //同Polyline的Option设置
            })
            break
          }
          case 'polygon': {
            this.$emit('toggleDistance', 7, {
              fillColor: '#0000ff',
              strokeColor: '#0000ff'
              //同Polygon的Option设置
            })
            break
          }
          case 'rectangle': {
            this.$emit('toggleDistance', 8, {
              fillColor: '#80d888',
              strokeColor: '#80d888'
              //同Polygon的Option设置
            })
            break
          }
          case 'circle': {
            this.$emit('toggleDistance', 9, {
              fillColor: '#00b0ff',
              strokeColor: '#80d8ff'
              //同Circle的Option设置
            })
            break
          }
        }
      }
    },
    // 实例化点标记(添加自定义设施)
    addMarker () {
      if (this.addMarkerShow) {
        this.addMarkerShow = false
        this.$emit('clearMap')
      } else {
        this.addMarkerShow = true
        this.$emit('clearMap')
        this.$emit('addMarker')
      }
    },
    //弹框关闭
    dialogFormClose () {
      this.$emit('clearMap')
      this.dialogFormVisible = false
    },
    //添加自定义设施
    addFacilitiesMarker () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          AddMap(this.form).then((res) => {
            if (res) {
              this.$message.success('新增自定义设施成功')
              this.dialogFormClose()
              if (this.facilitiesMarkerShow) {
                this.getFacilitiesMarkerList()
              }
            }
          })
        } else {
          return false
        }
      })
    },
    //获取添加的自定义设施列表
    getFacilitiesMarkerList () {
      getMapList().then((res) => {
        if (res) {
          this.$emit('renderMarker', res.data)
        }
      })
    },
    // 距离量算
    toggleDistance () {
      if (this.distanceShow) {
        this.distanceShow = false
        this.$emit('toggleDistance', 0)
      } else {
        this.distanceShow = true
        var option = {
          startMarkerOptions: {
            //可缺省
            icon: new AMap.Icon({
              size: new AMap.Size(19, 31), //图标大小
              imageSize: new AMap.Size(19, 31),
              image: require('@/assets/images/map/start.png')
            })
          },
          endMarkerOptions: {
            //可缺省
            icon: new AMap.Icon({
              size: new AMap.Size(19, 31), //图标大小
              imageSize: new AMap.Size(19, 31),
              image: require('@/assets/images/map/end.png')
            }),
            offset: new AMap.Pixel(-9, -31)
          },
          midMarkerOptions: {
            //可缺省
            icon: new AMap.Icon({
              size: new AMap.Size(19, 31), //图标大小
              imageSize: new AMap.Size(19, 31),
              image: require('@/assets/images/map/mid.png')
            }),
            offset: new AMap.Pixel(-9, -31)
          },
          lineOptions: {
            //可缺省
            strokeStyle: 'solid',
            strokeColor: '#FF33FF',
            strokeOpacity: 1,
            strokeWeight: 2
          }
        }
        this.$emit('toggleDistance', 1, option)
      }
    },
    // 测面积
    toggleArea () {
      if (this.areaShow) {
        this.areaShow = false
        this.$emit('toggleDistance', 0)
      } else {
        this.areaShow = true
        var option = {
          strokeColor: '#80d8ff',
          fillColor: '#80d8ff',
          fillOpacity: 0.3
          //同 Polygon 的 Option 设置
        }
        this.$emit('toggleDistance', 2, option)
      }
    },
    // 截图
    convertCanvasToImage (canvas) {
      var image = new Image()
      image.src = document
        .getElementsByClassName('amap-layer')[0]
        .toDataURL('image/png')
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.href = image.src
      a.download = '电子地图.png'
      a.click()
    },
    // 打印
    printCanvas () {
      window.print()
    },
    //设置地图显示要素
    setMapFeatures () {
      this.$emit('setMapFeatures', this.checkedOptions)
      // this.map.setFeatures(this.checkedOptions)
    },
    //清除量算绘图
    clearDraw () {
      this.drawTool.close(true)
      for (var i = 0; i < this.drawradios.length; i += 1) {
        if (this.drawradios[i].checked) {
          this.draw(this.drawradios[i].value)
        }
      }
    },
    //关闭量算绘图
    closeDraw () {
      this.drawTool.close(true) //关闭，并清除覆盖物
      for (var i = 0; i < this.drawradios.length; i += 1) {
        this.drawradios[i].checked = false
      }
    },
    // 路径分析
    pathAnalysis (key) {
      this.clearBusMarker()
      var drivingOption = {
        policy: AMap.DrivingPolicy.LEAST_TIME,
        map: this.map
      }
      switch (key) {
        case 1:
          this.driveTo(drivingOption)
          this.truckDrivingTo(drivingOption)
          break
        case 2:
          this.ridingTo(drivingOption)
          break
        case 3:
          this.driveTo(drivingOption)
          this.ridingTo(drivingOption)
          this.truckDrivingTo(drivingOption)
          break

        default:
          break
      }
    },
    //开车导航
    driveTo (drivingOption) {
      var driving = new AMap.Driving(drivingOption)
      driving.search(
        [{ keyword: this.startPlace }, { keyword: this.endPlace }],
        function (status, result) {
          // driving.searchOnAMAP({
          //   origin: result.origin,
          //   destination: result.destination
          // });
        }
      )
    },
    //骑车导航
    ridingTo (drivingOption) {
      //骑行导航
      var riding = new AMap.Riding(drivingOption)
      riding.search(
        [{ keyword: this.startPlace }, { keyword: this.endPlace }],
        function (status, result) { }
      )
    },
    //货车导航
    truckDrivingTo (drivingOption) {
      //货车导航
      var TruckDriving = new AMap.TruckDriving(drivingOption)
      TruckDriving.search(
        [{ keyword: this.startPlace }, { keyword: this.endPlace }],
        function (status, result) { }
      )
    },
    //去自定义设施列表
    toLocationTable () {
      if (this.showLocationTable) {
        this.showLocationTable = false
      } else {
        this.showLocationTable = true
      }
    }
  },
  created () { },
  mounted () { },
  watch: {}
}
</script>

<style lang="scss" scoped>
.tool-bar {
  @include themify() {
    .scaleBox {
      position: absolute;
      color: #333333;
      z-index: 10;
      background: #fff;
      width: 108px;
      top: 67px;
      right: 17px;
      .input-item {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        z-index: 10;
        background: #fff;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 34px;
        font-size: 14px;
        border-radius: 3px;
      }
      // .toolfalse:hover,
      .tooltrue {
        cursor: pointer;
        color: themed('b4');
      }
      .toolfalse:hover {
        cursor: pointer;
        color: themed('b4');
      }
      .toolfalse {
        cursor: pointer;
      }
    }
    .input-card {
      height: 0;
    }
  }
}
</style>
