<template>
  <div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="当前区域" name="first">
        <el-table :data="tableData" style="width: 100%" height="400">
          <el-table-column
            prop="cph"
            label="车牌号码"
            width="100"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="gpsTime"
            label="定位时间"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="longitude"
            label="经度"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="latitude"
            label="纬度"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="location"
            label="位置"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="历史车辆" name="second">
        <report-form
          @getFormVal="getFormVal"
          :needChoseCar="false"
        ></report-form>
        <el-table :data="carArr" style="width: 100%" height="300">
          <el-table-column prop="cph" label="车牌号码"></el-table-column>
          <el-table-column prop="inTime" label="进入时间"></el-table-column>
          <el-table-column prop="outTime" label="越出时间"></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div v-if="activeName == 'first'">
      <el-button
        v-if="tableData.length > 0"
        size="small"
        style="float: left"
        type="primary"
        @click="epxortCurrent"
        >导出</el-button
      >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="itotal1"
      ></el-pagination>
    </div>
    <div v-else>
      <el-button
        v-if="carArr.length > 0"
        style="float: left"
        size="small"
        @click="epxortHistory"
        type="primary"
        >导出</el-button
      >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="itotal2"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { queryCurrentPassCar, queryHistoryPassCar } from '@/api/lib/gps-api.js'
import { getTablePlaceArr } from '@/common/utils/index'
import reportForm from '@/components/reportForm/reportForm.vue'
export default {
  components: {
    reportForm
  },
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeName: 'first',
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        vehIds: [],
        system: '0',
        beginTime: '',
        endTime: ''
      },
      exportForm: {}, // 导出需要的数据
      carArr: [],
      itotal1: 0,
      itotal2: 0
    }
  },
  computed: {},
  mounted() {
    this.queryCurrentPassCar(this.form, 'getAll')
  },
  methods: {
    serch() {
      this.queryHistoryPassCar(this.form)
    },

    /* 导出 */
    epxortCurrent() {
      delete this.exportForm.pageSize
      delete this.exportForm.currentPage
    },
    epxortHistory() {
      delete this.exportForm.pageSize
      delete this.exportForm.currentPage
    },
    close() {
      this.$emit('outerVisibleClose')
    },
    /** 查询当前 */
    queryCurrentPassCar(data, isGetAll) {
      let self = this
      let parma = Object.assign(self.location, data)
      delete parma.beginTime
      delete parma.endTime
      queryCurrentPassCar(parma).then((res) => {
        if (res.code === 1000) {
          self.exportForm = { ...parma }
          self.tableData = res.data.list
          self.itotal1 = res.data.total
          getTablePlaceArr(res.data.list, 'longitude', 'latitude', self)
        }
      })
    },
    /** 查询历史 */
    queryHistoryPassCar(data) {
      let self = this
      let parma = Object.assign(self.location, data)
      queryHistoryPassCar(parma).then((res) => {
        if (res.code === 1000) {
          self.exportForm = { ...parma }
          self.carArr = res.data.list
          self.itotal2 = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getFormVal(v) {
      if (v) {
        this.form.beginTime = v.startTime
        this.form.endTime = v.endTime
        this.form.companyIds = v.companyIds.join()
        this.form.vehIds = v.vehIds.join()
        this.form.pageSize = 10
        this.form.currentPage = 1
        this.serch()
      }
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.switchGetData()
    },
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.switchGetData()
    },
    switchGetData() {
      switch (this.activeName) {
        case 'first':
          this.queryCurrentPassCar(this.form)
          break
        case 'second':
          if (this.carArr.length > 0) {
            this.queryHistoryPassCar(this.form)
          }
          break

        default:
          break
      }
    }
  }
}
</script>
<style scoped>
.el-dialog__body {
  padding-bottom: 4rem;
}
</style>
