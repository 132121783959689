<template>
  <el-container
    class="realVideo"
    :style="cardWidth[this.$store.state.vehicle.screenCoefficient]"
  >
    <div
      class="box-card"
      :style="cardWidth[this.$store.state.vehicle.screenCoefficient]"
    >
      <div id="fullscreen" class="card-body">
        <videoList
          ref="flvPlayModule"
          :buttonValue="buttonValue"
          @handleCloseVideo="handleCloseVideo"
        ></videoList>
      </div>
      <div slot="header" class="clearfix">
        <div class="buttons">
          <el-button
            size="small"
            type="text"
            @click="setPlayerNum(item.value, true)"
            v-for="item in buttons"
            :key="item.key"
            :sort="item.value == buttonValue ? 'primary' : 'grey'"
            :title="`${item.value}画`"
            ><i class="iconfont" :class="item.key"></i
          ></el-button>
        </div>
        <div class="operationBtn">
          <el-select
            v-if="$store.state.user.hdVideo == 1"
            v-model="isHD"
            :disabled="HDSwitchingStatus"
            @change="HDSwitching"
            placeholder="请选择"
            cellclassname="HDSelect"
          >
            <el-option label="标清" value="标清"> </el-option>
            <el-option label="高清" value="高清"> </el-option>
          </el-select>
          <el-button
            style="margin-left: 15px"
            type="text"
            size="small"
            sort="grey"
            title="停止"
            @click="stopAllVideo(false)"
            ><i class="iconfont its_bianzu24"></i
          ></el-button>
          <el-button
            size="small"
            type="text"
            sort="grey"
            title="全屏"
            @click="handleFullScreen"
            ><i class="iconfont its_quanping"></i
          ></el-button>
          <el-button @click="exit()" size="small" type="text" sort="danger"
            ><i class="iconfont its_tuichu1"></i>退出</el-button
          >
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import baseUrl from '@/api/static/config.json'

import {
  closeVehicleVideo,
  checkVideoStreams,
  addSpotCheck
} from '@/api/lib/refreshAlarm'

import videoList from './videoList.vue'

export default {
  name: 'realVideo',

  components: {
    videoList
  },
  props: {
    id: {
      type: [String, Number]
    },
    videoStyle: {
      type: String
    }
  },
  data () {
    return {
      vehicleId: '',
      timeRound: '',
      device: '', // 终端号
      channel: null, // 通道号
      protocolType: 1, // 协议类型,默认
      plate: '', // 当前选中车辆
      iframeWin: {},
      params: {}, // 传到视频界面参数
      buttons: [
        { key: 'its_1hua', value: 1 },
        { key: 'its_4hua', value: 4 },
        { key: 'its_6hua', value: 6 },
        { key: 'its_9hua', value: 9 },
        { key: 'its_16hua', value: 16 }
      ],
      fullscreen: false, // 全屏
      timer_time: null, // 播放定时器
      isHD: '标清', // 是否播放高清视频
      buttonValue: 0,
      form: {
        roundTreeSelect: [],
        seconds: 15
      },

      playVideoObj: new Map(), // 存放视频播放
      roundParams: [], // 确认视频通道是否打开
      cardWidth: {
        1.3: 'width:36vw;',
        1.15: 'width:45vw;',
        1: 'width:50vw;'
      }, // 整个面板的宽度
      timeExit: null,
      videoTimer: null,
      sendVideoObj: new Map(), // 下发指令的终端（等待回应15s，超过时间停止播放）
      switchHDProblemMap: new Map(), // 控件提示流断开的集合
      switchHDMap: new Map(), // 高清标清切换的obj
      HDSwitchingStatus: false, // 高标清正在切换
      HDSwitchingTimer: null,
      loading: null, // 高标清切换loading
      stopVideoStatus: false, // 正在关闭流
      stopVideoTimer: new Map(), // 一开始就需要关流的定时器
      videoOrderSrc: baseUrl.videoPortList, // 下发指令端口
      videoPortSrc: null, // 判断状态端口
      playVideoObjUrl: [] // 存放视频播放地址
    }
  },
  created () { },
  mounted: function () {
    /** 定时获取流是否可播放 */
    this.getVideoInterval()
    /** 定时获取下发指令的返回状态 */
    this.getStatusInterval()
    this.isHD = '标清'
    this.videoPortSrc = {
      10085: '4022',
      10086: '4023',
      10087: '4024',
      10088: '4025'
    }
    this.$nextTick(function () {
      this.setEventListener()
    })
  },

  methods: {
    /** 初始化 */
    init () {
      let self = this
      clearInterval(self.timer_tree)
    },
    /** 清除播放,定时器,监听事件 */
    clear () {
      this.stopAllVideo()
      clearInterval(this.timer_time)
      clearInterval(this.timeExit)
      clearInterval(this.videoTimer)
      clearInterval(this.HDSwitchingTimer)
      this.stopVideoTimer.forEach((value, key) => {
        if (value) clearInterval(value)
      })

      this.$yhsdp.RemoveRefreshCallBack(
        this.$RefreshTypeEnum.GPS_RealVideo,
        this.getGPS_RealVideoResult
      )
    },
    /** 切换高清/标清 */
    HDSwitching () {
      if (this.playVideoObj.size === 0) return
      clearInterval(this.timeExit)
      clearInterval(this.videoTimer)
      this.switchHDProblemMap.clear()
      const loading = this.$loading({
        lock: true,
        text: '正在切换中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.HDSwitchingStatus = true
      this.loading = loading
      let params = []
      let channels = [] // 流的存在检查
      this.playVideoObj.forEach((item, key) => {
        /** 高清/标清map */
        this.switchHDMap.set(key, item)
        /** 关闭视频 */
        this.stopParticularVideo(key)
        /** 关闭流 */
        this.$yhsdp.GBRealVideoStreamControlAll(item)
        params.push(item)
        channels.push(key)
      })
      let startTime = new Date()
      this.getStatusInterval()
      this.getVideoInterval()
      this.checkHDVideoStreams(channels, params, startTime)
    },
    /** 获取高清标清切换流断开的状态 */
    checkHDVideoStreams (channels, params, startTime) {
      let self = this
      /** 等待流断开 */
      self.HDSwitchingTimer = setInterval(() => {
        let timeDiff = new Date().getTime() - startTime.getTime()
        if (timeDiff >= 15000) {
          self.loading.close()
          self.$message.warning('等待响应时间过长，请稍后再试')
          self.HDSwitchingStatus = false
          self.clear()
          return
        }
        if (self.playVideoObj.size === 0) return
        checkVideoStreams({ channels: channels }).then((response) => {
          if (response && response.data.noPush.length === channels.length) {
            clearInterval(self.HDSwitchingTimer)
            params.forEach((item, index) => {
              /** 重新下发指令 新增playVideoObj map */
              self.$yhsdp.GBRealVideoStreamReq(item, self.isHD)
              self.sendVideoObj.set(channels[index], {
                date: new Date(),
                number: 1
              })
            })
            self.$refs.flvPlayModule.playVideo(params)
            self.loading.close()
            self.HDSwitchingStatus = false
          }
        })
      }, 1500)
    },
    /** 全屏事件 */
    handleFullScreen () {
      this.$refs.flvPlayModule.holeVideoFull()
    },

    /** 切换播放画面 */
    setPlayerNum (index, flag) {
      /** 点击切换播放画面 */
      if (flag) {
        if (this.playVideoObj.size === 0) {
          this.buttonValue = index
          return
        }
        this.buttonValue = index
        /** 多切少，关闭播放 */
        if (this.playVideoObj.size > index) {
          this.$refs.flvPlayModule.closePlayerNum(index)
        } else {
          /** 少切多，重新播放 */
          let params = []
          this.playVideoObj.forEach((value, key) => {
            params.push(`${value.device}_channel_${value.channel}`)
          })
          this.checkPlayerExit({ channels: params })
        }
      }
    },
    /** 监听实时视频是否下发指令成功   成功之后才调用视频的api播放 */
    setEventListener () {
      this.$yhsdp.AddRefreshCallBack(
        this.$RefreshTypeEnum.GPS_RealVideo,
        this.getGPS_RealVideoResult
      )
    },

    /** 下发指令成功|失败    */
    getGPS_RealVideoResult (data) {
      let str = data.flag ? '成功' : '失败'
      // console.log(
      //   `"下发指令${str}"`,
      //   `${data.data.device}_channel_${data.data.channel}`
      // )
    },
    /** 获取下发指令的状态 */
    getStatusInterval () {
      let self = this
      let carName = new Set()
      self.timeExit = setInterval(() => {
        if (self.sendVideoObj.size === 0) return
        self.sendVideoObj.forEach((value, key) => {
          if (value) {
            let time = new Date().getTime() - value.date.getTime() // 毫秒
            // 等待8秒
            if (time < 5000) {
              checkVideoStreams({ channels: [key] }).then((response) => {
                // console.log(
                //   `${key}请求是否有流存在：${response.data.localPush.join(
                //     ''
                //   )}+${response.data.noPush.join('')}`
                // )
                if (
                  response &&
                  response.data.localPush.length +
                  response.data.noPush.length ===
                  0
                ) {
                  self.sendVideoObj.delete(key)
                }
              })
            } else {
              if (value.number === 1 && self.playVideoObj.has(key)) {
                self.$yhsdp.GBRealVideoStreamReq(
                  self.playVideoObj.get(key),
                  self.isHD
                )
                self.sendVideoObj.set(key, {
                  date: new Date(),
                  number: 2
                })
              }
              if (value.number === 2) {
                self.sendVideoObj.delete(key)
                if (self.playVideoObj.has(key)) {
                  let data = self.playVideoObj.get(key)
                  self.stopParticularVideo(key)
                  let obj = self.playVideoObj.get(key)
                  self.playVideoObjUrl.splice(
                    self.playVideoObjUrl.findIndex(
                      (value) => value === obj.url
                    ),
                    1
                  )
                  self.playVideoObj.delete(key)
                  carName.add(data.plate)
                  self.$emit(
                    'closePlay',
                    `${data.vehId}`,
                    `${data.plate}摄像头${data.channel}`
                  )
                }
              }
            }
          }
        })
        if (carName.size > 0) {
          self.$message.warning(
            `${[...carName]}超时未应答，请检查网络情况后再试！`
          )
        }
        carName.clear()
      }, 3000)
    },
    //显示界面
    showVideo () {
      this.buttonValue = 4
    },
    /** 点击播放视频 */
    handleToPlayVideo (params, channel, flag, isInitHD) {
      if (isInitHD) this.initHD()
      let channelArr = []
      this.params = { ...params }
      channel.forEach((item) => {
        channelArr.push(`${this.params.device}_channel_${item}`)
      })
      this.params.channel = channel
      this.params.channelObj = {
        channels: channelArr
      }
      this.params.playTime = new Date()
      switch (flag) {
        case 'open':
          this.open()
          break
        case 'close':
          this.close()
          break
        default:
          break
      }
    },
    /** 播放 */
    open () {
      this.setVideoMap()
      //调用添加抽查信息
      this.addSpotCheck()
      this.changePlayerNum() // 设置新的窗口数
      this.checkPlayerExit(this.params.channelObj)
      if (this.switchHDProblemMap.has(this.params.device)) {
        this.switchHDProblemMap.delete(this.params.device)
      }
    },
    //添加抽查信息
    addSpotCheck () {
      addSpotCheck({
        type: 3,
        ids: this.params.vehId,
        system: sessionStorage.getItem('system')
      }).then()
    },

    /** 存储播放对象 */
    setVideoMap () {
      if (this.params.channel.length === 1) {
        let value = { ...this.params }
        delete value.channelObj
        value.channel = this.params.channel[0]
        this.playVideoObj.set(
          `${this.params.device}_channel_${this.params.channel[0]}`,
          value
        )
      }
      if (this.params.channel.length > 1) {
        this.params.channel.forEach((item, key) => {
          let value = { ...this.params }
          delete value.channelObj

          value.channel = item

          this.playVideoObj.set(`${this.params.device}_channel_${item}`, value)
        })
      }
    },
    /** 获取可用的端口地址 4路服务负载均衡 */
    checkPlayUrl (arr, port) {
      let videoOrderSrc = { ...this.videoOrderSrc }
      if (!arr.length && !port) {
        return Object.keys(videoOrderSrc)[0]
      }
      let res = {}

      // 遍历数组
      arr.forEach((item) => {
        if (item.length) {
          res[item] ? (res[item] += 1) : (res[item] = 1)
        }
      })
      /** 已存在的流是否可以使用该端口播放 */
      if (port && (res[port] < 6 || typeof res[port] !== 'number')) {
        return port
      }
      /** 遍历 res  出现次数最少的 */
      let minName = Object.keys(res)[0]
      let min = res[Object.keys(res)[0]]
      for (let r in videoOrderSrc) {
        if (typeof res[r] === 'number') {
          if (res[r] < min) {
            min = res[r]
            minName = r
          }
        } else {
          minName = r
          break
        }
      }
      return minName
    },

    /** 确认播放通道是否打开xxxx */
    checkPlayerExit (params) {
      // params.channels.forEach((item) => {
      //   let urlKey = this.checkPlayUrl(this.playVideoObjUrl)
      //   /* 可以直接下发指令的  获取可用的url */
      //   if (this.playVideoObj.has(item)) {
      //     /** 下发指令 */
      //     this.playVideoObjUrl.push(urlKey)
      //     let data = this.playVideoObj.get(item)
      //     data.url = urlKey
      //     data.port = this.videoOrderSrc[urlKey]
      //     this.$yhsdp.GBRealVideoStreamReq(data, this.isHD)
      //     this.sendVideoObj.set(item, {
      //       date: new Date(),
      //       number: 1
      //     })
      //   }
      // })
      // this.openIframe()
      checkVideoStreams(params).then((res) => {
        if (res) {
          let noPush = res.data.noPush.concat(res.data.localPush)
          let push = res.data.push
          if (noPush.length > 0) {
            noPush.forEach((item) => {
              let urlKey = this.checkPlayUrl(this.playVideoObjUrl)
              /* 可以直接下发指令的  获取可用的url */
              if (this.playVideoObj.has(item)) {
                /** 下发指令 */
                this.playVideoObjUrl.push(urlKey)
                let data = this.playVideoObj.get(item)
                data.url = urlKey
                data.port = this.videoOrderSrc[urlKey]
                this.$yhsdp.GBRealVideoStreamReq(data, this.isHD)
                this.sendVideoObj.set(item, {
                  date: new Date(),
                  number: 1
                })
              }
            })
          }
          if (push.length > 0) {
            /** 有流正在播放的 使用端口去播放 */
            push.forEach((value) => {
              let port = this.videoPortSrc[value.split(':')[1]]
              let urlKey = this.checkPlayUrl(this.playVideoObjUrl, port)
              this.playVideoObjUrl.push(urlKey)
              let data = this.playVideoObj.get(value.split(':')[0])
              data.url = urlKey
              data.port = this.videoOrderSrc[urlKey]
              /** 需要下发指令 */
              if (port !== urlKey) {
                this.$yhsdp.GBRealVideoStreamReq(data, this.isHD)
                this.sendVideoObj.set(value, {
                  date: new Date(),
                  number: 1
                })
              }
            })
          }
        }
        this.openIframe()
      })
    },

    /** 发送到iframe 播放界面 */
    openIframe () {
      let params = []
      this.playVideoObj.forEach((value, key) => {
        params.push(value)
      })
      this.$refs.flvPlayModule.playVideo(params)
    },

    /** 根据当前播放数量 设置对应的播放窗口 */
    changePlayerNum () {
      let index = this.buttons.find((item) => {
        return item.value >= this.playVideoObj.size
      })
      this.buttonValue = index.value
    },

    /** 点击暂停 */
    close () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.delVideoMap() // 关闭对应的窗口;

      this.openIframe() // 播放之前的窗口
      setTimeout(() => {
        loading.close()
      }, 2000)
    },
    /** 找到应删除对象 删除播放对象 */
    delVideoMap () {
      let params = []
      if (this.params.channel.length === 1) {
        let device = `${this.params.device}_channel_${this.params.channel[0]}`
        params.push(device)
        if (this.playVideoObj.has(device)) this.stopParticularVideo(device)
        if (this.playVideoObj.has(device)) {
          let obj = this.playVideoObj.get(device)
          this.playVideoObjUrl.splice(
            this.playVideoObjUrl.findIndex((value) => value === obj.url),
            1
          )

          this.playVideoObj.delete(device)
        }
        if (this.sendVideoObj.has(device)) this.sendVideoObj.delete(device)
      }
      if (this.params.channel.length > 1) {
        this.params.channel.forEach((item) => {
          let device = `${this.params.device}_channel_${item}`
          params.push(device)

          if (this.playVideoObj.has(device)) this.stopParticularVideo(device)
          if (this.playVideoObj.has(device)) {
            let obj = this.playVideoObj.get(device)
            this.playVideoObjUrl.splice(
              this.playVideoObjUrl.findIndex((value) => value === obj.url),
              1
            )

            this.playVideoObj.delete(device)
          }
          if (this.sendVideoObj.has(device)) this.sendVideoObj.delete(device)
        })
      }
      /** 调用接口 通知后台关流 出租车 */
      // closeVehicleVideo({ channels: params }).then((res) => {})
    },
    /** 暂停播放  对应icon点击的 */
    stopParticularVideo (params) {
      this.$refs.flvPlayModule.stopParticularVideo(params)
    },
    /** 停止全部 */
    stopAllVideo (flag) {
      let params = []
      this.playVideoObj.forEach((value, key) => {
        params.push(key)
      })
      this.playVideoObj.clear()
      this.sendVideoObj.clear()
      this.switchHDMap.clear()
      this.switchHDProblemMap.clear()
      this.playVideoObjUrl = []

      /** tree页面点击历史视频关闭实时视频 */
      if (!flag) this.$emit('stopAllVideo')
      this.$refs.flvPlayModule.flv_destroyAll()
      /** 调用接口 通知后台关流 */
      // closeVehicleVideo({ channels: params }).then((res) => {})
    },
    /** 退出视频界面 */
    exit () {
      let params = []
      this.playVideoObj.forEach((value, key) => {
        params.push(key)
      })
      this.playVideoObj.clear()
      this.sendVideoObj.clear()
      this.switchHDMap.clear()
      this.switchHDProblemMap.clear()
      this.playVideoObjUrl = []

      this.$emit('exit')
      this.$refs.flvPlayModule.flv_destroyAll()
      /** 调用接口 通知后台关流 */
      // closeVehicleVideo({ channels: params }).then((res) => {})
    },
    /* 点击页面上的关闭 */
    handleCloseVideo (params) {
      /** 调用接口 通知后台关流 */
      // closeVehicleVideo({ channels: [params] }).then((res) => {})
      /** 一般停止 */
      let obj = this.playVideoObj.get(params)
      this.playVideoObjUrl.splice(
        this.playVideoObjUrl.findIndex((value) => value === obj.url),
        1
      )
      /**这里使用map值 */
      this.$emit(
        'closePlay',
        `${obj.vehId}`,
        `${obj.plate}摄像头${obj.channel}`
      )
      this.playVideoObj.delete(params)
      this.sendVideoObj.delete(params)
    },
    /** 初始化 标清状态 */
    initHD () {
      if (this.playVideoObj.size === 0) this.isHD = '标清'
    },
    /** 定时获取流服务是否可用  为节省内存定时2分钟请求 */
    getVideoInterval () {
      let self = this
      let carName = new Set()
      if (self.videoTimer) {
        clearInterval(self.videoTimer)
      }
      self.videoTimer = setInterval(() => {
        if (self.playVideoObj.size === 0) return
        let params = []
        this.playVideoObj.forEach((item, key) => {
          params.push(key)
        })
        checkVideoStreams({ channels: params }).then((res) => {
          if (res) {
            let noPush = res.data.noPush.concat(res.data.localPush)
            if (noPush.length > 0) {
              noPush.forEach((key) => {
                /** 流不可用  关闭界面 */
                // if (self.sendVideoObj.has(key)) self.sendVideoObj.delete(key);

                if (self.playVideoObj.has(key) && !self.sendVideoObj.has(key)) {
                  let data = self.playVideoObj.get(key)
                  self.stopParticularVideo(key)
                  self.playVideoObjUrl.splice(
                    self.playVideoObjUrl.findIndex(
                      (value) => value === data.url
                    ),
                    1
                  )

                  self.playVideoObj.delete(key)
                  carName.add(data.plate)
                  self.$emit(
                    'closePlay',
                    `${data.vehId}`,
                    `${data.plate}摄像头${data.channel}`
                  )
                }
              })
            }
          }
          if (carName.size > 0) {
            self.$message.warning(
              `${[...carName]}流已断开，请检查网络情况后再试！`
            )
          }
          carName.clear()
        })
      }, 2 * 60 * 1000)
    }
  },
  /** 页面销毁 清除监听 */
  beforeDestroy () {
    this.clear()
  }
}
</script>

<style lang="scss" scoped>
.realVideo {
  position: absolute;
  border: 1px;
  top: 70px;
  right: 16px;
  z-index: 2000;
  background: #fff;
  .box-card {
    height: 45vh;
    .card-body {
      padding-top: 2px;
      width: 100%;
      height: 40vh;
    }
    .clearfix {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4vh;
      position: relative;
      border-top: 1px solid #ccc;
      padding: 0 20px;
      .operationBtn {
        margin-left: 16px;
        height: 32px;
      }
      span {
        font-size: 16px;
      }
      i {
        font-size: 16px;
      }
    }
  }
}
</style>
