<template>
  <div class="pl-table">
    <pl-table
      :data="trailTableData"
      :stripe="true"
      :resizable="false"
      :height="multipleTableHeight"
      :border="false"
      :highlight-current-row="false"
      ref="multipleTable"
      :row-height="45"
      :excess-rows="3"
      auto-resize
      @row-click="locatePoint"
      v-el-table-infinite-scroll="load"
    >
      <pl-table-column width="60">
        <template slot="header">
          <span @click="enlargeTable">
            <i class="el-icon-top head-icon"></i>
          </span>
          <span @click="closeTable">
            <i class="el-icon-bottom head-icon"></i>
          </span>
        </template>
        <template slot-scope="scope">
          <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
        </template>
      </pl-table-column>
      <pl-table-column
        prop="time"
        label="时间"
        :width="180"
        show-overflow-tooltip
      ></pl-table-column>
      <pl-table-column
        prop="speed"
        label="速度/限速值"
        :width="100"
        show-overflow-tooltip
      ></pl-table-column>
      <pl-table-column
        prop="angle"
        label="方向"
        show-overflow-tooltip
      ></pl-table-column>
      <pl-table-column label="定位状态" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.locate === null || scope.row.locate === 1
            ? "未定位"
            : "已定位"
        }}</template>
      </pl-table-column>
      <pl-table-column
        prop="mileage"
        label="里程数"
        show-overflow-tooltip
      ></pl-table-column>
      <pl-table-column
        prop="relativeMileage"
        label="相对里程"
        show-overflow-tooltip
      ></pl-table-column>
      <pl-table-column
        prop="component"
        label="部件状态"
        :width="240"
        show-overflow-tooltip
      ></pl-table-column>
      <pl-table-column
        prop="address"
        label="位置"
        :width="280"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span class="addressStyle">{{ scope.row.address }}</span>
        </template>
      </pl-table-column>
      <pl-table-column
        prop="alarm"
        label="报警信息"
        :width="200"
        show-overflow-tooltip
      >
      </pl-table-column>

      <pl-table-column
        prop="espeed"
        label="电子传感器速度"
        :width="120"
        show-overflow-tooltip
      ></pl-table-column>
    </pl-table>
    <el-button
      :disabled="tableTotal === 0"
      :loading="downLoadStatus"
      style="
        float: right;
        position: fixed;
        bottom: 10px;
        right: 25px;
        z-index: 10;
      "
      @click="epxortTrail"
      size="small"
      type="primary"
      class="sm-export"
      >导出</el-button
    >
    <div class="pagination">
      <span class="el-pagination__total">共 {{ tableTotal }} 条</span>
      <el-radio-group v-model="radio" size="mini" @change="selectTime">
        <el-radio-button
          v-for="(time, index) in timeArr"
          :key="index"
          :label="time"
          type="primary"
        ></el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { PlTable, PlTableColumn } from "pl-table";
import {
  getHistoryTrack,
  positionCount,
  addExcelExport,
} from "@/api/lib/refreshAlarm.js";
import { formatDay, getCurent } from "@/common/utils/index";
export default {
  name: "pagination",
  components: {
    PlTable,
    PlTableColumn,
  },
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  props: {},
  data () {
    return {
      paramData: {},
      buttonFlag: 1, // 展开轨迹表
      bigTrailTable: "half", // 轨迹表格高度
      trailTableData: [], // 轨迹表格
      trailTableDataBase: [], // 轨迹表格数据仓库
      tableLoading: false, // 轨迹表格加载
      tableTotal: 0, // 轨迹表格total
      trailTableStatus: false, // 轨迹表格正在加载
      loadMoreEnd: true, // 无限滚动是否结束
      queryStartTime: "", // 轨迹回放查询起始时间
      queryEndTime: "", // 轨迹回放查询结束时间
      isQueryEnd: false, // 轨迹回放查询结果是否小余100
      isLoadOver: false, // 轨迹回放是否可继续滚动加载
      changeTimeOver: true, // 切换时间时过程中不请求加载
      total: 0,
      page: 1,
      pageSize: 10,
      radio: "",
      timeArr: [],
      isFirstTimeLoading: true, //是否为第一次加载
      downLoadStatus: false, //导出
      exportForm: null,
    };
  },
  computed: {
    multipleTableHeight: function () {
      return this.bigTrailTable === "half"
        ? 262
        : this.bigTrailTable === "full"
          ? 469
          : 55; // pltable高度
    },
  },

  methods: {
    // 切换查询时间
    selectTime (value) {
      this.changeTime(value);
    },
    // 获取时间段按钮数据
    getTimeArr () {
      this.timeArr = [];
      const beginTime = new Date(formatDay(this.paramData.beginTime)).getTime();
      const endTime = new Date(formatDay(this.paramData.endTime)).getTime();
      const count = (endTime - beginTime) / (24 * 60 * 60 * 1000);
      for (let i = 0; i <= count; i++) {
        this.timeArr.push(formatDay(new Date(beginTime + i * 24 * 60 * 60 * 1000)));
      }
      this.radio = this.timeArr[0];
    },
    initFirstTimeLoading () {
      this.radio = this.timeArr[0];
      this.isFirstTimeLoading = true;
    },
    /* 展示某个点的位置 */
    locatePoint (row) {
      this.$emit("locatePointShow", row);
    },
    /** 轨迹表格放大 */
    enlargeTable () {
      this.bigTrailTable = this.bigTrailTable === "half" ? "full" : "half";
      let bottom = document.getElementsByClassName("el-icon-bottom");
      let obj = document.getElementsByClassName("el-icon-top");
      for (let i = 0; i < bottom.length; i++) {
        bottom[i].style.display = "inline-block";
      }
      for (let i = 0; i < obj.length; i++) {
        obj[i].style.display = this.bigTrailTable === "full" ? "none" : "inline-block";
      }
    },
    /** 轨迹表格缩小 */
    closeTable () {
      this.bigTrailTable = this.bigTrailTable === "full" ? "half" : "none";
      let top = document.getElementsByClassName("el-icon-top");
      let obj = document.getElementsByClassName("el-icon-bottom");

      for (let i = 0; i < top.length; i++) {
        top[i].style.display = "inline-block";
      }
      for (let i = 0; i < obj.length; i++) {
        obj[i].style.display = this.bigTrailTable === "none" ? "none" : "inline-block";
      }
      if (this.bigTrailTable === "none") {
        this.showTab = false;
        this.TabHeight = "0vh";
        this.mapHeight = "90vh";
      }
    },
    /* 展示轨迹表格 */
    showHistoryTable (data) {
      this.getTimeArr();
      delete data.startTime;
      delete data.currentPage;
      this.paramData = { ...data };
      this.resetTrailTable();
      if (this.$refs.timePage) this.$refs.timePage.initFirstTimeLoading();
      positionCount(this.paramData).then((res) => {
        if (res.code === 1000) {
          this.tableTotal = res.data;
        }
      });

      getHistoryTrack(this.paramData).then((res) => {
        if (res.code === 1000 && res.data.length) {
          // 将请求到的数据放入数据仓库中
          this.trailTableDataBase = res.data;
          this.queryEndTime = res.data[res.data.length - 1].time;
          // 从数据仓库中取10条数据放入轨迹回放表格中
          this.trailTableData = [...this.trailTableDataBase.splice(0, 10)];
          // this.showTrailReplayForm()
          if (this.$refs.multipleTable) this.$refs.multipleTable.doLayout();
          if (this.$refs.multipleTable) {
            this.$refs.multipleTable.pagingScrollTopLeft(0, 0);
          }
        } else {
          this.trailTableData = [];
        }
        this.exportForm = { ...data };
        this.isFirstTimeLoad = false;
      });
    },
    /** 加载轨迹表格 */
    load () {
      this.$nextTick(() => {
        this.append();
      });
    },

    /* 每次滚动加载从数据仓库中取出10条数据 */
    append () {
      if (this.trailTableDataBase.length === 0) return;
      this.trailTableData = [
        ...this.trailTableData,
        ...this.trailTableDataBase.splice(0, 10),
      ];
    },
    // 切换查询时间
    changeTime (val) {
      this.changeTimeOver = false;
      let date = `${val} 00:00:00`;
      if (this.paramData.beginTime.split(" ")[0] === val) {
        date = this.paramData.beginTime;
      }
      this.queryStartTime = date;
      // 重置查询轨迹回放报表
      this.trailTableData = [];
      this.trailTableDataBase = [];
      this.isQueryEnd = false;
      this.isLoadOver = false;
      const queryData = {
        system: "0",
        pageSize: 100,
        vehId: this.paramData.vehId,
        pageTime: date,
        beginTime: this.paramData.beginTime,
        endTime: this.paramData.endTime,
      };

      // 查询轨迹回放报表
      getHistoryTrack(queryData).then((res) => {
        if (res.code === 1000) {
          // 将请求到的数据放入数据仓库中
          this.trailTableDataBase = res.data;
          // 从数据仓库中取10条数据放入轨迹回放表格中
          this.changeTimeOver = true;
          this.trailTableData = [...this.trailTableDataBase.splice(0, 10)];

          this.$refs.multipleTable.doLayout();
          this.$refs.multipleTable.pagingScrollTopLeft(0, 0);
        }
        this.exportForm = { ...queryData };
      });
    },
    /** 导出轨迹 */
    epxortTrail () {
      let data = {
        baseUrl: "base",
        fileName: `轨迹信息-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/monitor/positionExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    resetTrailTable () {
      this.bigTrailTable = "half";
      this.isFirstTimeLoad = true;
      this.isQueryEnd = false;
      this.isLoadOver = false;
      this.trailTableData = [];
      this.trailTableDataBase = [];
      this.tableTotal = 0;

      if (this.$refs.pagination) this.$refs.pagination.reset();
    },
  },
  created () { },
  mounted () { },
  watch: {
    trailTableDataBase (val) {
      if (this.isLoadOver) return;
      if (!this.isQueryEnd && !this.isFirstTimeLoad && this.changeTimeOver) {
        // 轨迹回放查询结束时间为上次查询结果最后一条的时间
        if (val.length !== 0) {
          this.queryEndTime = val[val.length - 1].time;
        } else {
          this.queryEndTime = this.trailTableData[this.trailTableData.length - 1].time;
        }
        // 如果轨迹回放表格数据仓库存储的数据小余100条就重新请求100条数据
        if (val.length < 100) {
          this.isLoadOver = true;
          let data = {
            system: "0",
            pageSize: 100,
            vehId: this.paramData.vehId,
            pageTime: this.queryEndTime,
            beginTime: this.paramData.beginTime,
            endTime: this.paramData.endTime,
          };
          getHistoryTrack(data).then((res) => {
            if (res.code === 1000 && res.data.length) {
              // 如果查询结果小余100,则以后将不再请求数据
              if (res.data.length < 100) {
                this.isQueryEnd = true;
              }
              // 记录最后一条请求数据的时间
              this.queryEndTime = res.data[res.data.length - 1].time;
              /** young 后台处理 20200426 */
              // res.data = res.data.splice(1);
              this.isLoadOver = false;
              // 将请求到的数据添加到仓库中
              this.trailTableDataBase = [...this.trailTableDataBase, ...res.data];
            }
            this.exportForm = { ...data };
          });
        }
      }
    },
    "paramData.beginTime" (val) {
      this.getTimeArr();
    },
    "paramData.endTime" (val) {
      this.getTimeArr();
    },
    timeArr (val) {
      if (!this.isFirstTimeLoading) {
        this.$emit("selectTime", this.timeArr[0]);
      }
      this.isFirstTimeLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pl-table {
  @include themify() {
    width: 100%;
    position: absolute !important;
    bottom: 0;
    z-index: 160;
    .head-icon {
      color: themed("b4");
    }
    .pagination {
      height: 30px;
      background: transparent;
      position: fixed;
      bottom: 10px;
      right: 90px;
      z-index: 10;
    }
  }
}
</style>
