<template>
  <div class="sendInformation">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      label-width="82px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <!-- 已选车辆终端 -->
      <el-form-item label="车牌号：" v-if="!isBatch">
        <el-row>
          <el-col :span="12">{{ data.name }}</el-col>
          <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
        </el-row>
      </el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="isBatch"
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item
        label="选择车辆："
        v-if="isBatch"
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>

      <el-form-item label="消息类型" prop="messageType">
        <el-select v-model="form.messageType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="消息模板">
        <el-select
          v-model="form.module"
          placeholder="请选择消息模板"
          clearable
          @change="checkModule"
        >
          <el-option
            v-for="(item, key) in messageModule"
            :key="key"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 紧急下发 -->
      <el-form-item label="消息内容" prop="code">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="form.code"
        ></el-input>
      </el-form-item>
      <el-form-item label="    ">
        <br />
        <el-button type="primary" size="small" @click="save()"
          >确定下发</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import { queryMessageTemplateList } from '@/api/lib/api.js'

import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import { returnVideoArr } from '@/common/utils/index'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 **/
    isBatch: {
      type: Boolean,
      default: false
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    number: {
      type: Number,
      default: 300
    }
  },

  data() {
    return {
      deviceType: '', // 协议类型

      terminalNos: [],
      vehicleInfos: [],
      form: {
        messageType: '终端TTS播读',
        vehIds: [],
        companyIds: [],
        textarea: '',
        code: '',
        module: ''
      },
      messageModule: [],
      options: [
        {
          value: '紧急',
          label: '紧急'
        },
        {
          value: '终端TTS播读',
          label: '终端TTS播读'
        }
      ],
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],

        messageType: [
          {
            required: true,
            message: '请选择消息类型',
            trigger: 'blur'
          }
        ],

        code: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'blur'
          }
        ]
      }
    }
  },

  methods: {
    // 获取车组数据
    getGroupIds(val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.terminalNos = []
          this.vehicleInfos = []
          let ids = this.form.companyIds.concat(this.form.vehIds)
          queryDeviceByIds({
            ids: ids.join(','),
            system: this.deviceType
          }).then((res) => {
            if (res.code === 1000) {
              if (res.data.length === 0) {
                this.$message.warning('车辆没有绑定终端')
                return
              }
              res.data.forEach((element) => {
                this.terminalNos.push(element.deviceNo)
                this.vehicleInfos.push(`${element.cph}#${element.companyName}`)
              })
              if (this.terminalNos.length > this.number) {
                this.$message.warning(
                  `最多只能对${this.number}个终端批量下发指令`
                )
                return
              }
              this.$refs.form.validate((valid) => {
                if (valid) {
                  const sDispatchType = this.form.messageType
                  const sText = this.form.code

                  this.$yhsdp.GBSendMessage(
                    [this.terminalNos, this.vehicleInfos],
                    sDispatchType,
                    sText,
                    this.deviceType
                  )

                  this.$emit('close')
                }
              })
            }
          })
        }
      })
    },
    checkModule(id) {
      if (id) {
        let element = this.messageModule.find((item) => item.id === id)
        if (element) this.form.code = element.detail
      } else {
        this.form.code = ''
      }
    },
    //获取telno
    formatTelNo(videos) {
      return videos ? returnVideoArr(videos, true) : ''
    },
    getMessageList() {
      queryMessageTemplateList().then((res) => {
        if (res.code === 1000) {
          this.messageModule = res.data
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    }
  },
  created() {
    this.getMessageList()
  },
  mounted() {
    /** 单个车辆的数据 */
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.form.vehIds = [this.data.id]
    }
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.sendInformation {
  @include themify() {
    .el-row {
      .el-col {
        line-height: $base-input-height;
      }
    }
  }
}
</style>
