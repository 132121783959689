<template>
  <div class="video-form">
    <el-form :rules="rules" ref="form" :model="form">
      <!-- 开始时间： -->
      <el-form-item prop="day" label="日期范围：" label-width="92px">
        <el-date-picker
          v-model="form.day"
          align="right"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="time">
        <el-time-picker
          is-range
          v-model="form.time"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          format="HH:mm"
        >
        </el-time-picker>
      </el-form-item>

      <el-form-item label="  " label-width="22px">
        <el-button type="primary" size="small" @click="serch">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getStartDate,
  getCurentDate,
  formatDate,
  checkTimeLimit,
  checkLimitDate,
  formatDay
} from '@/common/utils/index'

export default {
  data() {
    let validateDate = (rule, value, callback) => {
      if (value && value !== '') {
        let day = formatDay(new Date())
        let startTime = `${day} ${formatDate(value[0]).split(' ')[1]}`
        let endTime = `${day} ${formatDate(value[1]).split(' ')[1]}`
        if (new Date(startTime) >= new Date(endTime)) {
          callback(new Error(`开始时间必须小于结束时间`))
        } else {
          callback()
        }
      } else {
        callback(new Error(`请选择`))
      }
    }

    return {
      timeDivance: 4, // 开始时间比当前时间提前4小时
      form: {
        time: null,
        day: new Date()
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              let now = new Date()
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              picker.$emit('pick', today)
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay * 7
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        day: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        time: [{ validator: validateDate, trigger: 'change' }]
      }
    }
  },
  methods: {
    /** 点击查询，传值到父组件 */
    serch() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {
            startTime: `${formatDay(this.form.day)} ${
              formatDate(this.form.time[0]).split(' ')[1]
            }`,
            endTime: `${formatDay(this.form.day)} ${
              formatDate(this.form.time[1]).split(' ')[1]
            }`
          }
          this.$emit('getFormVal', data)
        } else {
          return false
        }
      })
    },
    /** 重置表单 */
    resetForm() {
      if (this.selectCar) this.$refs.selectCarDom.clear()
      this.form.time = [new Date(getStartDate()), new Date(getCurentDate())]
    }
  },
  created() {
    let starTime =
      new Date(getCurentDate()).valueOf() - this.timeDivance * 60 * 60 * 1000
    let beginTime =
      new Date(getStartDate()) < new Date(starTime)
        ? new Date(starTime)
        : new Date(getStartDate())
    this.form.time = [beginTime, new Date(getCurentDate())]
  }
}
</script>

