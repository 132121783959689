<template>
  <div class="demo-form-inline dialog-form">
    <p>
      <strong>指令内容</strong>
    </p>
    <div class="instruction">
      <div class="ParametersBox">
        <p class="parameters">
          <span>车牌号 ： </span>
          <span class="parametersVal">{{ parameter.sVehNo }}</span>
        </p>
        <p class="parameters">
          <span>终端号 ： </span>
          <span class="parametersVal">{{ parameter.terminalNo }}</span>
        </p>
        <p class="parameters">
          <span>指令 ： </span>
          <span class="parametersVal">{{ parameter.sCmdTxt }}</span>
        </p>
      </div>
      <p class="title">指令参数</p>
      <div class="ParametersBox" v-if="parameter.param.length > 0">
        <p v-for="(item, i) in parameter.param" :key="i" class="parameters">
          <span>{{ item }}</span>
        </p>
      </div>
      <p v-else>无</p>
      <p>下发时间 ： {{ parameter.sTime }}</p>
    </div>

    <p>
      <strong>返回内容</strong>
    </p>
    <div class="instruction">
      <p class="title">返回参数</p>
      <!-- 查询终端属性-->

      <div
        class="ParametersBox attr"
        v-if="parameter.sNote.length > 0 && typeof parameter.sNote === 'object'"
      >
        <p v-for="(item, i) in parameter.sNote" :key="i" class="parameters">
          <span>{{ item.name }} ： </span>
          <span :title="item.value" class="parametersVal">{{
            item.value
          }}</span>
        </p>
      </div>
      <!-- 查询终端参数 -->
      <div
        v-else-if="parameter.sNote.alarm && typeof parameter.sNote === 'object'"
      >
        <p class="title">门限配置</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.alarm"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ： </span>
            <span :title="item.value" class="parametersVal">{{
              item.value
            }}</span>
          </p>
          <p class="parameters">
            <span>夜间限速比例(%) ： </span
            ><span :title="other.nightSpeedLimitPer" class="parametersVal">{{
              other.nightSpeedLimitPer
            }}</span>
          </p>
          <p class="parameters">
            <span>白天时间段 ： </span
            ><span :title="other.period" class="parametersVal">{{
              other.period
            }}</span>
          </p>
        </div>
        <p class="title">网络参数</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.net"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ： </span>
            <span :title="item.value" class="parametersVal">{{
              item.value
            }}</span>
          </p>
        </div>
        <p class="title">其他参数</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.other"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ： </span>
            <span :title="item.value" class="parametersVal">{{
              item.value
            }}</span>
          </p>
        </div>
      </div>
      <p v-else>{{ parameter.sNote }}</p>
      <p>返回时间 : {{ parameter.receiveTime }}</p>
    </div>
  </div>
</template>

<script>
import { queryDeviceParams } from '@/api/lib/api'
export default {
  props: {
    parameterObj: {
      type: Object,
      default: function (params) {
        return {
          sCmdTxt: '',
          param: [],
          sNote: []
        }
      }
    }
  },
  computed: {
    /** table的最大高度 */
    maxTabHeight() {
      return `${this.height - 50}px`
    }
  },
  data() {
    return {
      parameter: {
        sCmdTxt: '',
        param: [],
        sNote: []
      },
      other: {
        nightSpeedLimitPer: null,
        period: null
      }
    }
  },
  methods: {},
  mounted() {
    this.parameter = { ...this.parameter, ...this.parameterObj }
    if (this.parameter.sCmdTxt.indexOf('查询终端参数') > -1) {
      queryDeviceParams(this.parameter.terminalNo).then((res) => {
        if (res.code === 1000) {
          let period = null
          if (res.data.period) {
            let start = res.data.period.split('_')[0].split(' ')[1]
            let end = res.data.period.split('_')[1].split(' ')[1]
            period =
              start.substring(0, start.length - 3) +
              '-' +
              end.substring(0, end.length - 3)
          }
          this.other.nightSpeedLimitPer = res.data.nightSpeedLimitPer
          this.other.period = period
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
p.title {
  height: 36px;
  font-weight: 600;
}
.instruction {
  padding: 10px;
  p {
    height: 36px;
    display: flex;
  }
  .ParametersBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    p.parameters {
      width: calc(33%);
      .parametersVal {
        font-size: 14px;
        letter-spacing: -0.34px;
        text-align: right;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .attr {
    p.parameters {
      width: calc(90% - 30px);
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
