<template>
  <div class="operationPop" ref="pop" v-if="operationList.length > 0">
    <el-popover
      placement="bottom-start"
      trigger="hover"
      popper-class="operation-pop"
    >
      <div
        class="pop"
        @mouseleave="leave"
        v-if="data.status !== 0 && data.status !== null"
      >
        <ul>
          <li
            class="el-cascader-node"
            v-for="(item, key) in operationList"
            @mouseenter="operationEnter(item.index)"
            :key="key"
            @click="handleNodeClick(item)"
          >
            <span class="el-cascader-node__label">{{ item.name }}</span>
            <i
              v-if="item.children"
              class="el-icon-arrow-right el-cascader-node__postfix"
            ></i>
          </li>
        </ul>
        <!-- 二级菜单 暂时用不上 -->
        <ul>
          <li
            class="el-cascader-node"
            v-for="(item, key) in childOperationList"
            @click="handleNodeClick(item)"
            :key="key"
          >
            <span class="el-cascader-node__label">{{ item.name }}</span>
          </li>
        </ul>
      </div>

      <i
        class="iconfont its_icon_peizhi"
        :class="
          data.status !== 0 && data.status !== null ? 'active' : 'carOutline'
        "
        slot="reference"
        ref="icon"
      ></i>
    </el-popover>
    <!--操作列表-->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="showDialog"
      :width="dialoWidth"
      v-if="showDialog"
      :key="DialogIndex"
      append-to-body
      :custom-class="DialogIndex == 'addVehicle' ? 'add-vehicle-dialog' : ''"
    >
      <!--文本信息下发  -->
      <send-information
        v-if="DialogIndex == 'sendInformation'"
        :data="data"
        @close="handleCloseDialog"
      ></send-information>
      <!-- 设置终端参数 -->
      <terminal-parameter
        v-if="DialogIndex == 'terminalParameter'"
        :data="data"
        @close="handleCloseDialog"
      ></terminal-parameter>
      <!-- 设置服务器参数 -->
      <terminal-ip
        v-if="DialogIndex == 'terminalIp'"
        :data="data"
        @close="handleCloseDialog"
      ></terminal-ip>
      <!-- 设置IC卡参数 -->
      <make-card
        v-if="DialogIndex == 'ICardset'"
        :data="data"
        @close="handleCloseDialog"
      ></make-card>

      <!-- 下线时限配置 -->
      <div class="body" v-if="DialogIndex == 'carOfflineTime'">
        <el-form
          ref="addForm"
          :model="addForm"
          size="small"
          label-position="left"
          class="demo-form-inline dialog-form"
        >
          <!-- 已选车辆终端 -->
          <el-form-item label="车牌号：" label-width="92px">
            <el-row>
              <el-col :span="12">{{ data.name }}</el-col>
              <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="下线时限(分钟)：" label-width="140px">
            <el-input-number
              v-model="addForm.time"
              placeholder="请输入"
              :min="0"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="长时间离线(分钟)：" label-width="140px">
            <el-input-number
              v-model="addForm.longTime"
              placeholder="请输入"
              :min="0"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="    " style="text-align: center">
            <br />
            <el-button size="small" @click="showDialog = false">取消</el-button>
            <el-button type="primary" size="small" @click="showDialog = false"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 设置运营线路 -->
      <div class="body" v-if="DialogIndex == 'setOperationLine'">
        <el-form
          ref="form"
          :model="form"
          size="small"
          :rules="rules"
          label-position="left"
          label-width="92px"
          class="demo-form-inline dialog-form"
        >
          <!-- 已选车辆终端 -->
          <el-form-item label="车牌号：">
            <el-row>
              <el-col :span="12">{{ data.name }}</el-col>
              <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="运营线路：" prop="operateLine">
            <el-input
              v-model="form.operateLine"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="DialogIndex == 'setOperationLine'"
      >
        <el-button type="default" size="small" @click="showDialog = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="saveOperateLine"
          >保存</el-button
        >
      </span>
      <!-- 设置运营线路 -->

      <!-- 终端升级 -->
      <terminalUpgrade
        :settingValue="settingValue"
        v-if="DialogIndex == 'terminalAttributes'"
        :data="data"
        @close="handleCloseDialog"
      ></terminalUpgrade>
      <!-- 查询驾驶员信息 -->
      <dirverInfo
        :driverInfo="driverInfo"
        v-if="DialogIndex == 'driverManage'"
        @close="handleCloseDialog"
      ></dirverInfo>

      <!-- 客运车辆禁行设置 -->
      <carDrivingHibit
        :data="data"
        v-if="DialogIndex == 'carDrivingHibit'"
        @close="handleCloseDialog"
      ></carDrivingHibit>

      <!-- 修改车辆信息 -->
      <addVehicle
        ref="addVehicle"
        :vehicleNo="vehicleNo"
        :rowData="rowData"
        v-if="DialogIndex == 'addVehicle'"
        @saveEnd="saveEnd"
        @onLoading="onLoading"
      ></addVehicle>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="DialogIndex == 'addVehicle'"
      >
        <el-button type="default" size="small" @click="showDialog = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/static/operationList'
// 部标设备指令
import sendInformation from '@/components/orderOperation/sendInformation.vue' // 文本信息下发
import terminalParameter from '@/components/orderOperation/terminalParameter.vue' // 设置终端参数
import terminalIP from '@/components/orderOperation/terminalIP.vue' // 设置服务器参数
import makeCard from '@/components/orderOperation/makeCard.vue' // 设置服务器参数
import terminalUpgrade from '@/components/orderOperation/terminalUpgrade.vue' //终端升级
import dirverInfo from '@/components/orderOperation/dirverInfo.vue' //查询驾驶员信息
import carDrivingHibit from '@/components/orderOperation/carDrivingHibit.vue' //客运车辆禁行设置

import addVehicle from '@/views/projectResourse/basicData/vehicleManagement/components/addVehicle.vue' //修改车辆信息

import { queryDeviceByIds, getPostionVhiclePositions } from '@/api/lib/refreshAlarm.js'
import {
  updateVehicleLine,
  queryDriver,
  getVehicleById
} from '@/api/lib/api.js'

import { returnVideoArr } from '@/common/utils/index'

export default {
  name: 'pop',
  components: {
    'send-information': sendInformation,
    'terminal-parameter': terminalParameter,
    'terminal-ip': terminalIP,
    'make-card': makeCard,
    terminalUpgrade,
    dirverInfo,
    carDrivingHibit,
    addVehicle
  },
  props: {
    data: {
      type: [String, Number, Object]
    }
  },
  data () {
    return {
      operationList: [], // 所有操作菜单
      showChildMenu: false,
      childOperationList: [],
      telNos: [],
      vehicleInfos: [],
      deviceType: '', // 协议类型
      title: '',
      showDialog: false,
      statusMap: [],
      settingValue: '',
      timeExit: null,
      addForm: {
        time: 10,
        longTime: 60
      },
      form: { operateLine: null },
      rules: {
        operateLine: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      driverInfo: null,
      rowData: null,
      vehicleNo: null,
      editLoading: false
    }
  },
  methods: {
    onAddChildNode () {
      this.$emit('nodeOperation', this.data)
    },
    operationEnter (index) {
      const list = this.operationList.find((item) => index === item.index)
      if (list) this.childOperationList = list.children
    },
    leave (e) {
      this.childOperationList = []
    },
    /** 根据权限获取可操作菜单 */
    getMenuCtrl () {
      this.operationList = []
      api.operationList.forEach((element) => {
        if (this.$store.state.menu.nowMenuList.indexOf(element.name) > -1) {
          this.operationList.push(element)
        }
      })
    },
    /** 点击操作菜单 */
    handleNodeClick (data) {
      const name = data.name // 菜单的名称
      const index = data.index
      const type = data.type
      const width = data.width

      this.toOperationPage(name, index, type, width)
    },
    /** 点击操作菜单 */
    toOperationPage (name, index, type, width) {
      if (type === 'order') {
        // 只是下发指令,没有具体页面的
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          (res) => {
            if (res.code === 1000) {
              let vehicle = res.data[0]
              this.telNos = [vehicle.deviceNo]
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`]
              this.handleOrder(name, index)
            }
          }
        )
        return
      } else if (index === 'terminalAttributes') {
        // 查询终端属性--终端升级
        queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
          (res) => {
            if (res.code === 1000) {
              let vehicle = res.data[0]
              this.telNos = [vehicle.deviceNo]
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`]
              this.$yhsdp.GBQueryAttributes(
                [this.telNos, this.vehicleInfos],
                this.deviceType
              )
              this.statusMap = [
                {
                  time: new Date(),
                  width: width,
                  name: name,
                  index: index
                }
              ]
              this.$yhsdp.AddRefreshCallBack(
                this.$RefreshTypeEnum.GB_QueryAttributes,
                this.getAttributesSeting
              )
              this.getStatusInterval()
            }
          }
        )
        return
      } else if (index === 'driverManage') {
        // 查看驾驶员
        queryDriver(parseInt(this.data.id.replace('v', ''))).then((res) => {
          if (res.code === 1000) {
            if (!res.data) {
              this.$message.warning(
                '当前车辆无驾驶员登签或驾驶员信息未录入平台'
              )
            } else {
              this.driverInfo = { ...res.data }
              this.dialoWidth = width !== '' ? width : '60%'
              this.showDialog = true
              this.title = name
              this.DialogIndex = index
            }
          }
        })
        return
      } else if (index === 'addVehicle') {
        // 修改车辆信息
        getVehicleById(parseInt(this.data.id.replace('v', ''))).then((res) => {
          if (res.code === 1000) {
            if (!res.data) {
              this.$message.warning(res.msg)
            } else {
              this.rowData = { ...res.data }
              this.vehicleNo = res.data.vehicleNo
              this.dialoWidth = width !== '' ? width : '60%'
              this.showDialog = true
              this.title = name
              this.DialogIndex = index
            }
          }
        })
        return
      }
      else if (index === 'setOperationLine') {
        // 设置运营线路
        getPostionVhiclePositions({
          ids: this.data.id,
          system: sessionStorage.getItem('system'),
          currentPage: 1,
          pageSize: 10
        }).then((res) => {
          if (res.code === 1000) {
            if (res.data.list.length != 0 && res.data.list[0].line) {
              this.form.operateLine = res.data.list[0].line
            } else {
              this.form.operateLine = null
            }
            this.dialoWidth = width !== '' ? width : '60%'
            this.showDialog = true
            this.title = name
            this.DialogIndex = index
          }
        })

        return
      }
      this.dialoWidth = width !== '' ? width : '60%'
      this.showDialog = true
      this.title = name
      this.DialogIndex = index
    },
    /** 设置操作菜单界面,点击关闭   单个点名不显示指令信息 */
    handleCloseDialog (flag) {
      this.showDialog = false
      if (!flag) {
        this.$emit('nodeOperation', 'pushTableToAct')
      }
    },
    /** 直接下发指令不需要填写参数 */
    handleOrder (name, index) {
      let flag = false // 点名，不去定位到指令信息
      switch (index) {
        case 'deviceCall': // 点名
          this.$yhsdp.GBCalling(
            [this.telNos, this.vehicleInfos],
            this.deviceType
          )
          flag = true
          this.$emit('nodeOperation', 'carCalling', this.data) // 默认选中,显示

          break
        case 'QueryParameter': // 查询终端参数
          this.$yhsdp.GBQueryParameters(
            [this.telNos, this.vehicleInfos],
            null,
            this.deviceType
          )
          break
      }
      if (!flag) this.$emit('nodeOperation', 'pushTableToAct')
    },
    /** 获取查询终端参数报警屏蔽设置的状态 */
    getStatusInterval () {
      const self = this
      self.timeExit = setInterval(() => {
        if (self.statusMap.length === 0) return

        const time = new Date().getTime() - self.statusMap[0].time.getTime() // 毫秒
        if (time >= 15000) {
          self.statusMap = []
          self.$message.warning('返回超时，请检查网络情况后再试！')
          clearInterval(self.timeExit)
        }
      }, 2000)
    },
    getAttributesSeting (res) {
      const self = this
      if (self.statusMap.length === 0) return
      const { width, name, index } = self.statusMap[0]
      self.dialoWidth = width !== '' ? width : '60%'
      self.title = name
      self.DialogIndex = index
      self.settingValue = res.result
      self.settingValue.telNos = self.telNos
      self.settingValue.vehicleInfos = self.vehicleInfos
      self.statusMap = []
      clearInterval(self.timeExit)
      self.showDialog = true
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    },
    //设置营运线路
    saveOperateLine () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {
            id: parseInt(this.data.id.replace('v', '')),
            operateLine: this.form.operateLine
          }
          updateVehicleLine(data).then((res) => {
            if (res.code === 1000) {
              this.$message.success('修改成功')
              this.showDialog = false
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    onSave () {
      this.$refs.addVehicle.onSave()
    },
    saveEnd () {
      this.showDialog = false
    },
    onLoading (flag) {
      this.editLoading = flag
    }
  },
  created () {
    this.getMenuCtrl()
  },
  mounted () {
    this.deviceType = sessionStorage.getItem('system')
  },
  destroyed () {
    clearInterval(self.timeExit)
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GB_QueryAttributes,
      this.getAttributesSeting
    )
  }
}
</script>

<style lang="scss" scoped>
.operationPop {
  display: inline-block;
  @include themify() {
    .active {
      color: themed("b4");
    }
    .carOutline {
      color: #dcdedf;
    }
  }
}
</style>
