<template>
  <div class="body makeCard">
    <div class="form">
      <el-form
        :model="makeForm"
        :rules="rules"
        ref="makeForm"
        label-width="102px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <!-- <el-form-item label="IC卡号" prop="cardNo">
          <el-input type="text" v-model="makeForm.cardNo"></el-input>
        </el-form-item> -->
        <el-form-item label="制卡终端车辆" prop="carId">
          <el-input type="text" v-model="makeForm.carId" disabled></el-input>
        </el-form-item>
        <el-form-item label="驾驶员信息" prop="driverId">
          <el-select
            v-model="makeForm.driverId"
            filterable
            remote
            reserve-keyword
            placeholder="请选择"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="changeDriver"
          >
            <el-option
              v-for="item in driverList"
              :key="item.id"
              :label="item.driverName + '-' + item.idCard"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="驾驶员姓名" prop="driverName">
          <el-input
            type="text"
            v-model="makeForm.driverName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="驾驶证号" prop="drivingLicense">
          <el-input
            type="text"
            v-model="makeForm.drivingLicense"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="从业资格证证号" prop="credentialsNo">
          <el-input
            type="text"
            v-model="makeForm.credentialsNo"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button size="small" @click="cancle">取消</el-button>
        <el-button type="primary" size="small" @click="onSave">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { queryDriverByName, makeCardParams } from '@/api/lib/api'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
let Base64 = require('js-base64').Base64

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      telNos: [],
      vehicleInfos: [],
      makeForm: {
        carId: '',
        driverId: ''
      },
      driverList: [],
      loading: false,
      rules: {
        cardNo: [{ required: true, message: '不能为空', trigger: 'blur' }],
        driverId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        icNumber: [{ required: true, message: '不能为空', trigger: 'change' }],
        produce: [{ required: true, message: '不能为空', trigger: 'change' }]
      }
    }
  },

  methods: {
    // 制卡
    onSave() {
      this.$refs.makeForm.validate((valid) => {
        if (valid) {
          let params = ''
          queryDeviceByIds({
            ids: this.data.id,
            system: this.deviceType
          }).then((res) => {
            if (res.code === 1000) {
              if (res.data.length === 0) {
                this.$message.warning('车辆没有绑定终端')
                return
              }
              let vehicle = res.data[0]
              this.telNos = [vehicle.deviceNo]
              this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`]
              this.getCardParams()
            }
          })
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia(flag) {
      this.$emit('close', flag)
    },
    // 模糊搜索
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          queryDriverByName({ driverName: query }).then((res) => {
            if (res.code === 1000) {
              this.driverList = res.data
            }
          })
        }, 200)
      } else {
        this.driverList = []
      }
    },
    // 选中已搜索的司机的信息匹配
    changeDriver() {
      this.driverList.forEach((item) => {
        if (item.id === this.makeForm.driverId) {
          this.makeForm.driverName = item.driverName
          this.makeForm.drivingLicense = item.drivingLicense
          this.makeForm.credentialsNo = item.credentialsNo
        }
      })
    },
    //获取ic卡参数
    getCardParams() {
      makeCardParams({
        deviceNo: this.telNos[0],
        driverId: this.makeForm.driverId
      }).then((res) => {
        if (res.code === 1000) {
          this.$yhsdp.GBSetICParam(
            [this.telNos, this.vehicleInfos],
            ['显示+播报', Base64.decode(res.data)],
            this.deviceType
          )
          this.$emit('close')
        }
      })
    },
    cancle() {
      this.$emit('close', true)
    }
  },
  created() {},
  mounted() {
    this.makeForm.carId = this.data.name
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>

<style lang="scss" scoped>
.makeCard {
  width: 100%;
  @include themify() {
    .footer {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
