<template>
  <div class="body driver-info">
    <p class="form-title">驾驶员基本信息</p>
    <el-form
      :model="addForm"
      :inline="true"
      ref="addForm"
      label-width="110px"
      label-position="left"
      :disabled="true"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="驾驶员姓名：" prop="name">
        <el-input
          v-model.trim="addForm.name"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>

      <el-form-item label="所属企业：" prop="companyId">
        <el-input
          v-model.trim="addForm.companyName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>

      <el-form-item label="工号：" prop="workNum">
        <el-input
          v-model.trim="addForm.workNum"
          placeholder="请输入工号"
        ></el-input>
      </el-form-item>

      <el-form-item label="性别：" prop="sex">
        <el-select clearable v-model.trim="addForm.sex">
          <el-option
            v-for="item in sexList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="身份证号码：" prop="idCard">
        <el-input
          v-model.trim="addForm.idCard"
          placeholder="请输入身份证号码"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系电话：" prop="phone">
        <el-input
          v-model.trim="addForm.phone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>

      <el-form-item label="星级：" prop="starLevel">
        <el-select clearable v-model.trim="addForm.starLevel">
          <el-option
            v-for="item in starLevelList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <p class="from-title form-title3">驾驶员照片</p>
    <div class="form-area photo-area">
      <ul
        class="el-upload-list el-upload-list--picture-card"
        v-if="addForm.photos.length > 0"
      >
        <li
          class="el-upload-list__item"
          v-for="(item, key) in addForm.photos"
          :key="key"
        >
          <img :src="item" alt="" class="el-upload-list__item-thumbnail" />
        </li>
      </ul>
    </div>

    <p class="form-title form-title3">
      <span>从业资格证信息</span>
    </p>
    <el-form
      :model="addForm2"
      :inline="true"
      :disabled="true"
      ref="addForm2"
      label-width="110px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="资格证号：" prop="credentialsNo">
        <el-input
          v-model.trim="addForm2.credentialsNo"
          placeholder="请输入从业资格证号"
        ></el-input>
      </el-form-item>
      <el-form-item label="到期时间：" prop="credentialsEndDate">
        <el-date-picker
          v-model="addForm2.credentialsEndDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
    </el-form>

    <p class="form-title form-title3">
      <span>驾驶证信息</span>
    </p>
    <el-form
      :model="addForm3"
      :disabled="true"
      ref="addForm3"
      label-width="110px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="驾驶证号码：" prop="drivingLicense">
        <el-input
          v-model.trim="addForm3.drivingLicense"
          :disabled="true"
          placeholder="请输入驾驶证号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="到期时间：" prop="drivingEndDate">
        <el-date-picker
          v-model="addForm3.drivingEndDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="驾驶证类型：" prop="drivingType">
        <el-select clearable v-model.trim="addForm3.drivingType">
          <el-option
            v-for="item in drivingTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryDictsByCodes } from '@/api/lib/api.js'
export default {
  props: {
    /**  */
    driverInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      addForm: {
        name: '',
        companyName: '',
        workNum: '',
        sex: '',
        idCard: '',
        status: '0',
        phone: '',
        starLevel: '',
        photos: []
      },
      addForm2: {
        credentialsNo: '',
        credentialsEndDate: ''
      },
      addForm3: {
        drivingType: '',
        drivingLicense: '',
        drivingEndDate: ''
      },
      sexList: [],
      statusList: [],
      starLevelList: [],
      drivingTypeList: []
    }
  },

  methods: {
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'ZT,JSZLX,XB,XJ' }).then((res) => {
        if (res.code === 1000) {
          this.statusList = res.data.ZT
          this.drivingTypeList = res.data.JSZLX
          this.sexList = res.data.XB
          this.starLevelList = res.data.XJ
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    let row = { ...this.driverInfo }
    this.addForm = {
      id: row.id,
      name: row.name,
      companyName: row.companyName,
      workNum: row.workNum,
      sex: row.sex + '',
      idCard: row.idCard,
      status: row.status + '',
      phone: row.phone,
      starLevel: row.starLevel + ''
    }
    this.addForm.photos =
      row.driverPhoto && row.driverPhoto.length > 0 ? row.driverPhoto : []
    this.addForm2 = {
      credentialsNo: row.credentialsNo,
      credentialsEndDate: row.credentialsEndDate
    }
    this.addForm3 = {
      drivingType: row.drivingType,
      drivingLicense: row.drivingLicense,
      drivingEndDate: row.drivingEndDate
    }
  }
}
</script>
<style lang="scss" scoped>
.driver-info {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .form-title3 {
      margin: 10px 0 10px;
    }
    .photo-area {
      padding-bottom: 16px;
    }
  }
}
</style>
