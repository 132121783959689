<template>
  <div class="rollCall">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      label-width="82px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item
        label="选择车辆："
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    number: {
      type: Number,
      default: 300
    }
  },

  data() {
    return {
      deviceType: '', // 协议类型

      terminalNos: [],
      vehicleInfos: [],
      form: {
        vehIds: [],
        companyIds: []
      },
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    // 获取车组数据
    getGroupIds(val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const terminalNos = []
          const vehicleInfos = []
          let ids = this.form.companyIds.concat(this.form.vehIds)
          queryDeviceByIds({
            ids: ids.join(','),
            system: this.deviceType
          }).then((res) => {
            if (res.code === 1000) {
              if (res.data.length > 0) {
                res.data.forEach((item) => {
                  terminalNos.push(item.deviceNo)
                  vehicleInfos.push(`${item.cph}#${item.companyName}`)
                })
                this.terminalNos = terminalNos
                this.vehicleInfos = vehicleInfos
                if (this.terminalNos.length > this.number) {
                  this.$message.warning(
                    `最多只能对${this.number}个终端批量下发指令`
                  )
                  return
                }
                this.$yhsdp.GBCalling(
                  [this.terminalNos, this.vehicleInfos],
                  this.deviceType
                )
              } else {
                this.$message.warning('车辆没有绑定终端')
              }
              this.$emit('close')
            }
          })
        }
      })
    }
  },
  mounted() {
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
