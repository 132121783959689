<template>
  <div class="carTree" :style="setCarTreeHeight">
    <div class="expand">
      <div class="formTab">
        <div style="display: flex">
          <el-autocomplete
            class="inline-input"
            clearable
            v-model="filterText"
            :fetch-suggestions="querySearch"
            placeholder="请输入车组/车牌号"
            :trigger-on-focus="false"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
            </template>
          </el-autocomplete>
          <el-button
            style="margin: 0 10px"
            size="small"
            :disabled="searchStatus"
            @click="search"
            :type="searchStatus ? 'info' : 'primary'"
            >搜索</el-button
          >
          <!-- <el-dropdown>
            <i class="operationMore iconfont iconicon_screen" title="车辆筛选"></i>
            <el-dropdown-menu slot="dropdown">
              <div style="padding:0 10px 10px 10px">
                <p>车辆筛选：</p>
                <div>
                  <el-radio-group v-model="radio">
                    <el-radio :label="0">全部</el-radio>
                    <el-radio :label="1">在线</el-radio>
                    <el-radio :label="2">离线</el-radio>
                  </el-radio-group>
                </div>
                <div></div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>-->
          <!-- 批量下发指令 -->
          <batchPop @nodeOperation="handleContextMenu" />
        </div>
      </div>
      <div class="tree-box" :style="setTreeBoxHeight">
        <el-tree
          :props="props"
          :load="loadNode"
          lazy
          ref="tree"
          :data="setTree"
          :render-content="renderContent"
          @check="handleCheck"
          show-checkbox
          node-key="id"
          class="expand-tree"
          :default-expanded-keys="defaultExpandKeys"
          :filter-node-method="filterNode"
          :auto-expand-parent="true"
        ></el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryAllNode,
  getVehicleGroupTree,
  searchTree,
  getVhicleStatus
} from '@/api/lib/refreshAlarm.js'
import TreeRender from './index.vue'
import batchPop from './components/batchPop.vue'
import { returnVideoArr } from '@/common/utils/index'
/**
 * 技术点:
 * 1.数据量过大优化加载速率--懒加载(已加载数据不会再加载了)
 * 2.勾选公司返回所有车辆数据(筛选非车辆数据传给后台)
 * 3.搜索: (搜索符号长度大于2才搜索)
 * 搜索把懒加载模式关闭,直接赋值tree
 * 搜索完:
 * 存储选中节点,再渲染;setcheckkeys
 * 视频选中状态需要重新渲染;-------渲染完去根据id获取,把状态值改变
 * 点击删除,回到最原始状态
 * 4.勾选,点击,展开事件都要去更新在离线车辆数据
 * 5.勾选:在地图上显示车辆位置,车辆总数超过200个,用批量显示点api
 * 6.查看视频:车辆下线,acc关需要关闭视频;取消选中状态
 *7.更新车辆状态:
 *已选中查看视频的需要更新状态,acc下线需关闭,车辆下线需关闭
 *8.点击更新在离线车辆数据
 */
export default {
  // 实时监控||轨迹回放||实时视频||历史视频  树形组件
  components: {
    batchPop
  },
  props: {
    /** 功能识别 0可以操作树 >1轨迹回放一系列 */
    trailStep: {
      type: Number
    },
    showCheckBox: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    },
    CarTreeHeight: {
      type: String,
      default: '40vh'
    },
    treeBoxHeight: {
      type: String,
      default: '28vh'
    },
    /**系统  gps|公交|出租 按照数据字典 */
    system: {
      type: [Number, String],
      default: 0
    }
  },

  data () {
    return {
      props: {
        label: 'name',
        children: 'children',
        isLeaf: 'tag'
      },
      radio: 0,
      loadingNode: null, // 懒加载请求node
      loadResolve: null, // 懒加载请求返回
      lazy: true, // 懒加载模式
      defaultExpandKeys: [], // 搜索车辆后默认展开节点列表
      onExpandKeys: [], // 存储车组展开节点列表
      visible: false,
      isFirst: true, // 第一次进行加载
      isLoadingTree: false, // 是否加载节点树
      setTree: null, // 节点树数据
      timer_tree: null, // 车辆状态定时器
      reqStatus: 0, // 进入页面和轮询请求的区分     0-进入页面调用 1-轮询调用
      checkCarId: '', // 历史视频被选中的车辆id
      channelength: '', // 通道长度
      device: '', // 设备号
      vehicleId: '',
      filterText: '', // 关键字
      filterId: '', // 关键字代表的id
      searchStatus: false, // 未点击搜索
      resource: 0, // 在线状态  //请求车辆状态参数     null-全部 0-在线 1-离线
      Node: null,
      selectArr: new Map(), // 实时视频已选择的摄像头数据
      selectFatherArr: new Map(), // 实时视频已选择的车辆数据
      reqData: {
        id: 0,
        checked: false,
        keyWord: ''
      },
      node: [],
      timer_bus: null,
      checkedKeys: [], // 被选中的节点,搜索完需要去回显
      buttons: [1, 4, 6, 9, 16], // 视频可播放界面参数
      vehid: []
    }
  },
  mounted () {
    this.queryAllNode() // 定时更新各公司车队在线车辆数
    this.setTreeStatusInterval() //定时获取车辆在离线状态
  },

  beforeDestroy () {
    clearInterval(this.timer_tree)
  },

  computed: {
    /** tree的高度 */
    setCarTreeHeight () {
      return `height:${this.CarTreeHeight}`
    },
    /** 组件整体的高度 */
    setTreeBoxHeight () {
      return `height:${this.treeBoxHeight}`
    }
  },

  methods: {
    /** 定时更新各公司车队在线车辆数 */
    queryAllNode () {
      var self = this
      self.timer_bus = setInterval(() => {
        queryAllNode({ system: 0 }).then((res) => {
          var obj = {}

          res.data.forEach((item) => {
            obj[item.id] = item.name
          })
          self.node.forEach((item) => {
            item.childNodes.forEach((element) => {
              if (element.data.type < 3) {
                element.data.name = obj[element.data.id]
              }
            })
          })
        })
      }, 10000)
    },

    /** 显示树形组件icon */
    renderContent (h, { node, data, store }) {
      // 加载节点
      const that = this
      return h(TreeRender, {
        props: {
          DATA: data,
          NODE: node,
          STORE: store,
          expand: that.expand,
          plate: that.plate,
          checkCarId: that.checkCarId
        },
        on: {
          toPlayVideo: (s, d, n) => that.handleToPlayVideo(s, d, n),
          nodeclick: (s, d, n) => that.handleNodeClick(s, d, n),
          nodeOperation: (type, node) => that.handleContextMenu(type, node),
          nodeCheck: (s, d, n) => that.handleHistoryVideo(s, d, n)
        }
      })
    },

    /** 树的懒加载 */
    loadNode (node, resolve) {
      this.requestTreeNode(node, resolve)
    },
    /**
     * 公共函数——调用后台请求数据
     * @node[node]: 要加载的子节点的节点数据
     * @resolve: 用于resolve数据
     */
    requestTreeNode (node, resolve) {
      this.node.push(node)
      if (resolve) {
        // 判断是否是懒加载过来的请求
        node.loading = true
        /** 第一层级公司名 */
        if (node.level === 0) {
          getVehicleGroupTree({ ...this.reqData, system: this.system }).then(
            (res) => {
              resolve(res.data)
              node.loading = false
            }
          )
        } else {
          /** 根据公司id或车组id展开下一层(车辆展开摄像头不请求) */
          /** 车辆展开不需要请求 */
          if (node.data.type === 3) {
            const channel = []
            if (node.data.videos) {
              const videoNum = node.data.videos
                ? returnVideoArr(node.data.videos)
                : 0
              const telNo = node.data.videos
                ? returnVideoArr(node.data.videos, true)
                : ''
              for (let i = 0; i < videoNum; i++) {
                const arr = {
                  id: `${node.data.id}_${node.data.name}摄像头${i + 1}`,
                  type: 4,
                  name: `摄像头${i + 1}`,
                  sa: this.selectArr.has(
                    `${node.data.id}_${node.data.name}摄像头${i + 1}`
                  )
                    ? [`${node.data.id}_${node.data.name}摄像头${i + 1}`]
                    : [],
                  status: node.data.status,
                  acc: node.data.acc,
                  telNo: telNo,
                  tag: true
                }
                channel.push(arr)
              }
            }
            resolve(channel)
            node.loading = false
          } else {
            /** 公司|车队展开下一层 */
            getVehicleGroupTree({
              id: node.data.id,
              type: node.data.type,
              checked: false,
              system: this.system,
              keyWord: ''
            }).then((res) => {
              resolve(res.data)
              node.loading = false
              if (node.data.type === 1) {
                this.onExpandKeys = [...this.onExpandKeys, ...res.data]
              }
            })
          }
        }
      }
    },

    /* 清空选中 */
    resetChecked () {
      if (!this.$refs.tree) return
      this.$refs.tree.setCheckedKeys([])
    },
    /** 下拉框模糊搜索 */
    querySearch (queryString, cb) {
      this.filterId = ''
      searchTree({ keyWord: queryString, system: this.system }).then((res) => {
        cb(res.data)
      })
    },
    /** 模糊搜索|点击节点 */
    handleSelect (item) {
      if (item.type === 3) {
        this.filterId = 'v' + item.id
      } else {
        this.filterId = item.id
      }
      this.filterText = item.name
    },
    /** 关键字查询 */
    search () {
      if (this.searchStatus) return
      if (!this.filterText) {
        this.$refs.tree.filter()
        return
      }
      if (!this.filterId) {
        this.$message.warning('请选择精确结果后查询')
        return
      }
      this.searchStatus = true
      getVehicleGroupTree({
        id: 0,
        checked: false,
        system: this.system,
        keyWord: this.filterId
      }).then((res) => {
        const expandList = []
        res.data.split(',').forEach((value) => {
          expandList.push(value.indexOf('v') < 0 ? Number(value) : value)
        })
        this.defaultExpandKeys = expandList
        if (expandList.length === 1) {
          if (expandList[0].indexOf('c') > -1) {
            this.searchStatus = false
          }
        } else {
          setTimeout(() => {
            this.$refs.tree.filter(
              this.defaultExpandKeys[this.defaultExpandKeys.length - 1]
            )
            this.searchStatus = false
          }, 800)
        }
      })
    },
    /** *搜索完后，渲染完节点筛选数据 */
    filterNodeMethod (id) {
      const lastIndex = this.defaultExpandKeys[
        this.defaultExpandKeys.length - 1
      ]
      const hasCar = lastIndex.indexOf('c') > -1
      /** 查询车辆，车辆层级会比他的父级早渲染 */
      if (hasCar) {
        // if (!this.searchStatus) return;
        setTimeout(() => {
          this.$refs.tree.filter(lastIndex)
        }, 5000)
        this.searchStatus = false
      } else {
        if (id !== lastIndex || !this.searchStatus) return
        this.searchStatus = false
        this.$refs.tree.filter(lastIndex)
      }
    },
    /** 过滤字符 */
    filterNode (value, data, node) {
      if (!value) return true
      const _array = [] // 这里使用数组存储 只是为了存储值。返回其子元素
      this.getReturnNode(node, _array, value)
      let result = false
      _array.forEach((item) => {
        result = result || item
      })
      return result
    },
    /** 获取筛选数据的子数据 */
    getReturnNode (node, _array, value, type) {
      let isPass = ''
      isPass = node.data && value === node.data.id
      _array.push(isPass || '')
      if (!isPass && node.level !== 1 && node.parent) {
        this.getReturnNode(node.parent, _array, value)
      }
    },
    /** 定时获取车辆在离线状态 */
    setTreeStatusInterval () {
      const self = this
      if (self.timer_tree) {
        clearInterval(self.timer_tree)
      }
      const intervalIimerFn = function () {
        const companyIds = []
        if (!self.$refs.tree) return
        self.onExpandKeys.forEach((item) => {
          if (
            item.type === 2 &&
            self.$refs.tree.getNode(item.id) &&
            self.$refs.tree.getNode(item.id).expanded
          ) {
            companyIds.push(item.id)
          }
        })
        if (!self.$refs.tree) return
        if (companyIds.length === 0) return
        /** 更新车辆状态-- 获取全部车辆数据   */
        getVhicleStatus({ companyIds: companyIds.join(',') }).then((res) => {
          if (res.code !== 1000) {
            return
          }
          res.data.forEach((item) => {
            const vehid = `v${item.vehId}`
            const node = self.$refs.tree.getNode(vehid)
            if (node) {
              if (item.status !== null) {
                self.$set(node.data, 'status', item.status)
              }
              self.$set(node.data, 'acc', item.acc)
              if (item.status !== null) {
                if (item.status === 0) {
                  /** 已下线 */
                  /** 断开视频请求 */
                  self.cutVideoPlay(node, vehid)
                  /** 改变车辆状态 */
                  self.changeCarStatus(node.data, node.childNodes)
                } else {
                  /** 改变车辆状态 */
                  self.changeCarOnlineStatus(node.data, node.childNodes)
                }
              }
              /** acc状态关 */
              if (item.acc === 1) {
                self.cutVideoPlay(node, vehid)
              }
            }
          })
        })
      }
      self.timer_tree = setInterval(() => {
        intervalIimerFn()
      }, 10000)
    },
    /** 定时请求车辆状态 车辆下线acc断开时断开视频请求 */
    cutVideoPlay (node, vehid) {
      const self = this
      /** 历史视频正在播放的 */
      const checkCarId = self.checkCarId.split('_')[0]
      if (checkCarId === vehid) {
        self.stopAllHistoryVideo()
        self.$emit('stopHistioryVideoCard')
        self.$message.warning(`车辆${node.data.name}ACC关,视频不可观看`)
      }
      /** 实时视频正在播放的 */
      const videoNum = node.data.videos ? returnVideoArr(node.data.videos) : 0
      const telNo = node.data.videos
        ? returnVideoArr(node.data.videos, true)
        : ''

      const flag =
        self.checkChildPlayStatus(node.data.id, node.data.name, videoNum) ||
        (node.data.sa && node.data.sa.length > 0)
      if (flag) {
        // acc关需要关闭视频
        self.stopChannel = []
        self.$set(node.data, 'sa', [])
        const params = {
          vehId: node.data.id, // id
          device: telNo, // 终端号
          plate: node.data.name // 车牌号
        }
        // 切断所有摄像头
        self.clearChild(node.data, node.childNodes)
        // 把摄像头的acc状态也关闭
        self.clearChildAcc(node.data, node.childNodes)
        self.$emit('handleToPlayVideo', params, self.stopChannel, 'close')
        self.$message.warning(`车辆${node.data.name}ACC关,视频不可观看`)
      }
    },
    /** 车辆下线，改变摄像头相应状态 */
    changeCarStatus (data, childNodes) {
      // 把摄像头的在线|acc状态也关闭
      const videoNum = data.videos ? returnVideoArr(data.videos) : 0

      for (var i = 0; i < videoNum; i++) {
        if (childNodes[i]) {
          this.$set(childNodes[i].data, 'acc', 1)
          this.$set(childNodes[i].data, 'status', 0)
        }
      }
    },
    /** 车辆上线，改变相应状态 */
    changeCarOnlineStatus (data, childNodes) {
      const videoNum = data.videos ? returnVideoArr(data.videos) : 0
      if (childNodes.length > 0) {
        for (var i = 0; i < videoNum; i++) {
          if (childNodes[i]) {
            this.$set(childNodes[i].data, 'status', data.status)
            this.$set(childNodes[i].data, 'acc', data.acc)
          }
        }
      }
    },
    /** 点击节点 */
    handleNodeClick (data, node, el) {
      if (node.type === 3) {
        if (this.trailStep > 1) {
          this.$message.warning('请先退出轨迹回放')
          return
        }
        this.$refs.tree.setCheckedKeys([node.id])
        this.$emit('handleNodeClick', data, node, el)
      }
    },

    /** 点击复选框   */
    handleCheck (data, checkedNodes) {
      this.checkedKeys = checkedNodes.checkedKeys
      this.$emit('handleCheck', data, checkedNodes)
    },

    /** 查看车辆实时视频 */
    handleToPlayVideo (s, d, n) {
      /** 懒加载模式 d没有children参数 */
      const parent = n.parent
      if (n.data.type === 4) {
        if (parent.data.status === null || parent.data.status === 0) {
          this.$message.warning('车辆不在线,不可查看视频')
          return
        }
        if (parent.data.acc === 1) {
          this.$message.warning('ACC关,不可查看视频')
          return
        }
      } else {
        if (n.data.status === null || n.data.status === 0) {
          this.$message.warning('车辆不在线,不可查看视频')
          return
        }
        if (n.data.acc === 1) {
          this.$message.warning('ACC关,不可查看视频')
          return
        }
      }

      this.channel = []
      this.stopChannel = []
      const data = n.data

      /** 拼装车辆的数据 */
      const video = data.videos ? returnVideoArr(data.videos) : 0
      this.device =
        data.type === 3 ? returnVideoArr(data.videos, true) : data.telNo
      this.plate = data.type === 3 ? data.name : n.parent.data.name // 车牌号
      this.vehicleId = data.type === 3 ? data.id : n.parent.data.id // 车辆id
      this.channelength = data.type === 3 ? video : 1 // 车辆通道号长度
      const params = {
        vehId: this.vehicleId, // id
        device: this.device, // 终端号
        plate: this.plate // 车牌号
      }
      if (data.sa && data.sa.length > 0) {
        data.sa = []
        this.selectArr.delete(data.id)
        // 切断播放
        if (data.type === 3) {
          // 切断所有摄像头
          this.selectFatherArr.delete(data.id)
          this.clearChild(data, n.childNodes)
        } else {
          const channelstr = data.id
          this.stopChannel.push(channelstr.charAt(channelstr.length - 1))
          // 如果此选项清空勾选后，要把所有的父元素，也全部清空勾选，因为它不勾选了，所有父元素的状态不可能还处于勾选状态，不管它勾选不勾选，我们都要清除一遍，以防万一
          if (this.selectFatherArr.has(n.parent.data.id)) {
            this.selectFatherArr.delete(n.parent.data.id)
          }
          // n.parent.data.sa = []
          this.$set(n.parent.data, 'sa', [])
        }
        this.$emit('handleToPlayVideo', params, this.stopChannel, 'close')
      } else {
        this.openStatus = 0
        if (!this.checkObjLength()) return
        this.$set(data, 'sa', [data.id])

        // 如果此选项勾选后，如果下面有摄像头的话，那么也同时勾选下面所有的孩子
        if (data.type === 3) {
          this.selectFatherArr.set(`${data.id}`, true)
          this.addChild(data, n.childNodes)
        } else {
          const channelstr = data.id
          this.selectArr.set(`${data.id}`, true)
          this.channel.push(channelstr.charAt(channelstr.length - 1))
          this.selectFather(n.parent.data, n.parent.childNodes)
        }
        /** 如果正在播放历史视频，停止 */
        if (this.checkCarId) {
          this.checkCarId = ''
        }
        this.$emit('handleToPlayVideo', params, this.channel, 'open')
      }
    },
    /** 下发指令 */
    handleContextMenu (type, node) {
      this.$emit('handleContextMenu', type, node)
    },
    /** 判断当前播放数量 是否超过最大的可播放窗口值 */
    checkObjLength () {
      const objLength = this.selectArr.size + this.channelength
      const max = this.buttons[this.buttons.length - 1]
      if (objLength > max) {
        this.$alert(`播放画面超过${max}个,请先关闭一部分`, '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true
        })
        return false
      } else {
        return true
      }
    },
    /** 超过播放时限 清除选中状态 */
    closePlay (parentId, itemId) {
      const id = `${parentId}_${itemId}`
      const node = this.$refs.tree.getNode(parentId)
      const item = this.$refs.tree.getNode(id)
      if (node && this.selectFatherArr.has(parentId)) {
        this.$set(node.data, 'sa', [])
        this.selectFatherArr.delete(parentId)
      }
      if (item) {
        this.$set(item.data, 'sa', [])
      }
      if (this.selectArr.has(id)) {
        this.selectArr.delete(id)
      }
    },

    /***
     * 定义函数清空所有孩子的勾选
     *
     *
     *  */
    clearChild (data, children) {
      const videoNum = data.videos ? returnVideoArr(data.videos) : 0
      for (var i = 0; i < videoNum; i++) {
        this.selectArr.delete(`${data.id}_${data.name}摄像头${i + 1}`)
        this.stopChannel.push(i + 1)
        if (children[i]) children[i].data.sa = []
      }
    },

    /** 定义函数添加所有孩子的勾选 */
    addChild (data, children) {
      const videoNum = data.videos ? returnVideoArr(data.videos) : 0

      for (var i = 0; i < videoNum; i++) {
        this.selectArr.set(`${data.id}_${data.name}摄像头${i + 1}`, true)
        if (children[i]) {
          children[i].data.sa = [`${data.id}_${data.name}${i + 1}`]
        }
        this.channel.push(i + 1)
      }
    },
    /** 确认摄像头是否全部选中=>车辆是否选中 */
    selectFather (father, fatherChildren) {
      let data = 0
      fatherChildren.findIndex((item) => {
        if (item.data.sa && item.data.sa.length > 0) data++
      })
      if (data === fatherChildren.length) {
        this.selectFatherArr.set(father.id, true)
        this.$set(father, 'sa', [father.id])
      }
    },
    /** 当前默认选中node */
    setCurrentNode (id) {
      this.$refs.tree.setCheckedKeys(id)
    },
    /** 摄像头acc状态设置 */
    clearChildAcc (data, childNodes) {
      const videoNum = data.videos ? returnVideoArr(data.videos) : 0

      for (var i = 0; i < videoNum; i++) {
        if (childNodes[i]) this.$set(childNodes[i].data, 'acc', data.acc)
      }
    },
    /** 播放历史视频 */
    handleHistoryVideo (tree, d, n) {
      const parent = n.parent
      const data = n.data
      if (data.type !== 4) {
        this.$message.warning('请点击对应车辆摄像头')
        return
      }
      if (parent.data.status === null || parent.data.status === 0) {
        this.$message.warning('此车辆未在线')
        return
      }
      if (parent.data.acc === 1 || typeof parent.data.acc === 'string') {
        this.$message.warning('ACC关,不可查看视频')
        return
      }
      let params = {
        name: parent.data.name,
        videos: parent.data.videos
      }

      if (this.checkCarId === data.id) {
        this.checkCarId = ''
        this.$emit('handleHistoryVideo', data, params, 'close')
      } else {
        /** 如果正在播放实时视频，停止 */
        if (this.selectArr.size > 0) {
          this.stopAllVideo()
          this.$emit('stopRealVideoCard')
        }
        this.checkCarId = data.id // 川H00002摄像头2
        this.$emit('handleHistoryVideo', data, params, 'open')
      }
    },
    /** 实时视频停止所有播放时 */
    stopAllVideo () {
      /** 清除所有播放状态 */
      this.selectArr.forEach((item, key) => {
        const node = this.$refs.tree.getNode(key)
        this.stopChannel = []
        this.channel = []
        if (node) this.$set(node.data, 'sa', [])
      })
      this.selectFatherArr.forEach((item, key) => {
        const node = this.$refs.tree.getNode(key)
        this.stopChannel = []
        this.channel = []
        if (node) this.$set(node.data, 'sa', [])
      })
      this.selectArr.clear()
      this.selectFatherArr.clear()
    },
    /** 历史视频停止所有播放时 */
    stopAllHistoryVideo () {
      this.checkCarId = ''
    },
    /** 当acc关时，车辆下的某几个摄像头也需要关闭 */
    checkChildPlayStatus (parentId, name, length) {
      let flag = 0
      for (let i = 0; i < length; i++) {
        const element = `${parentId}_${name}摄像头${i + 1}`
        if (this.selectArr.has(element)) flag += 1
      }
      return flag > 0
    },
    /** 车辆面板-实时视频  */
    toHandlePlayVideo (vehId, name) {
      const node = this.$refs.tree.getNode(vehId)
      if (!node) {
        this.filterId = vehId
        this.filterText = name
        this.search()
        setTimeout(() => {
          this.handleToPlayVideo(null, null, this.$refs.tree.getNode(vehId))
        }, 1200)
      } else {
        this.handleToPlayVideo(null, null, node)
      }
    },
    /** 历史视频 - channel切换 */
    toHandlePlayHisVideo (id) {
      this.checkCarId = id
    },
    /** 车辆面板-轨迹回放  */
    toHandleTrail (vehId, name) {
      let self = this
      const node = this.$refs.tree.getNode(vehId)
      if (!node) {
        this.filterId = vehId
        this.filterText = name
        this.search()
        setTimeout(() => {
          this.$refs.tree.setChecked(vehId, true)
          let checkedNodes = self.$refs.tree.getCheckedNodes()
          this.$emit('handleCheck', this.$refs.tree.getNode(vehId), {
            checkedNodes
          })
        }, 1200)
      } else {
        this.$refs.tree.setChecked(vehId, true)
        let checkedNodes = self.$refs.tree.getCheckedNodes()
        this.$emit('handleCheck', node, { checkedNodes })
      }
    }
  },

  destroyed () {
    clearInterval(this.timer_bus)
  }
}
</script>

<style lang="scss" scoped>
.carTree {
  .expand {
    .formTab {
      padding: 0 10px 5px;
      .online-button {
        align-items: center;
        margin: 15px 0;
        label {
          flex: 1;
        }
      }
      .el-input {
        font-size: 1vh;
        display: block;
        .el-input__inner {
          height: 4vh !important;
        }
      }
      .el-dropdown {
        height: 34px;
      }
      .operationMore {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        background: #fff;
        margin-right: 10px;
      }
    }
    .tree-box {
      height: 28vh;
      width: 18vw;
      margin-left: 10px;
      font-size: 14px;
      overflow-y: auto;

      .expand-tree {
        border: none;
        // overflow-x: auto;

        box-shadow: 0px 2px 8px 0px rgba(199, 200, 201, 0.5);
        background: #fff;

        .el-tree-node.is-current,
        .el-tree-node:hover {
          overflow: hidden;
        }

        .is-current > .el-tree-node__content .tree-btn,
        .el-tree-node__content:hover .tree-btn {
          display: inline-block;
        }
        .is-current > .el-tree-node__content .tree-label {
          font-weight: 600;
          white-space: normal;
        }
      }
    }
  }
}
</style>
<style lang="scss">
/* .tree-box .is-leaf + .el-checkbox .el-checkbox__inner {
  visibility: hidden;
} */

.carTree .el-tree-node > .el-tree-node__children {
  overflow: visible;
  &::-webkit-scrollbar {
    display: none;
  }
}
.carTree .el-tree > .el-tree-node > .el-tree-node__children {
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: inline-block;
  }
}
</style>
